import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import TemplateImage from "../../../assets/images/document-check.png";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function TemplateCard(props) {

 const { title, handleTemplateClick } = props
  return (
    <Card onClick={handleTemplateClick}>
      <CardActionArea>
      <Grid container style={{height: "10rem"}}>
        <Grid item xs={8} md={8}>
          <CardContent sx={{ flex: "1 0 auto", margin: "15px" }}>
            <Typography component="div" variant="h6">
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="span"
            >
              Template
            </Typography>
          </CardContent>
         
        </Grid>
        <Grid item xs={4} md={4}>
        <CardMedia
            component="img"
            image={props.templateImage}
            alt="Template"
          />
        </Grid>
      </Grid>
     </CardActionArea>
    </Card>
  );
}

TemplateCard.defaultProps = {
  templateImage: TemplateImage,
};
