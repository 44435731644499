import React, {
  useState,
  useId,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// for parcel build
// import { Document, Page } from 'react-pdf/dist/esm/entry.parcel2';

import "./index.css";
/**
 * https://github.com/wojtekmaj/react-pdf
 *
 * Use by adding <Document file="..." />. file can be a URL, base64 content, Uint8Array, and more.
 * Put <Page /> components inside <Document /> to render pages.
 *
 * Sample files:
 * - https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK
 *
 *
 * @returns <PdfLoader />
 */
const PdfLoader = React.forwardRef((props, pdfLoaderRef) => {
  const id = useId();
  const [noOfPages, setNoOfPages] = useState(1);
  const pdfFile = props.pdfFileData;

  const onDocumentLoadSuccess = function (pages) {
    console.log("Document Loaded", pages);
    setNoOfPages(pages.numPages);
  };

  return (
    <>
      {/* <p>PDF Loader document is below</p> */}
      <Document
        className="react-pdf__Document"
        file={pdfFile}
        // file={samplePDF}
        onLoadSuccess={onDocumentLoadSuccess}
        ref={pdfLoaderRef}
      >
        {/* <Page pageNumber={1}/> */}
        {Array.apply(null, Array(noOfPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <Page
              key={`page-${id}-${page}`}
              id={`page-${id}-${page}`}
              pageNumber={page}
              styles={{ border: "1px solid black" }}
            />
          ))}
          
      </Document>
    </>
  );
});


export default PdfLoader;
