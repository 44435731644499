import axios from "axios";
import { setDocumentListData, setDocumentData } from "../../documents/documents";
import { instanceOf } from "prop-types";

let controller = null;

export const getAllDocuments = (params={}) => async dispatch => {
  try {

    if (controller) {
        controller.abort("Previous request aborted");
    }
    controller = new AbortController()

    let query = new URLSearchParams(params).toString();
    query = (query?('?'+query):'')

    const response = await axios.get("/documents" + query , {
      signal: controller.signal
    });

    dispatch(setDocumentListData(response.data))
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const getAllDocumentsOfAccount = () => async dispatch => {
  try {
    const response = await axios.get("/account_documents");
    dispatch(setDocumentListData(response.data))
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const getDocumentByID = async (documentId) => {
  try {
    const response = await axios.get(`/documents/${documentId}`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const createDocument = async (documentData) => {
  try {
    const response = await axios.post("/documents", documentData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to create document");
  }
};

// Function to create a document
export const createDocumentWithPDF = async (documentData) => {
  try {
    const formData = new FormData();

    for (const key in documentData) {
      if (key === "fileUploadData") {
        formData.append("fileUploadData", documentData[key]);
      } else {
        formData.append(key, (documentData[key] instanceof Object)?JSON.stringify(documentData[key]):documentData[key]);
      }
    }

    const response = await axios.post("/senddocument", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to create document", error);
  }
};

// Function to create a document
export const signDocument = async (documentData) => {
  try {
    const formData = new FormData();

    for (const key in documentData) {
      if (key === "fileUploadData") {
        formData.append("fileUploadData", documentData[key]);
      } else {
        formData.append(key, (documentData[key] instanceof Object)?JSON.stringify(documentData[key]):documentData[key]);
      }
    }

    const response = await axios.put("/signdocument", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to create document", error);
  }
};


export const updateDocument = async (documentId, updatedDocumentData) => {
  try {
    const response = await axios.put(
      `/documents/${documentId}`,
      updatedDocumentData
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const deleteDocument = async (documentId) => {
  try {
    const response = await axios.delete(`/documents/${documentId}`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};
