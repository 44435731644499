import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import SpeedDial from "../../components/SpeedDial/SpeedDial";
import "./styles.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllDocuments, getAllDocumentsOfAccount } from "./../../actions/api/document/documentAction"
import useAuth from "../../hooks/domain/useAuth";
import useSearch from "../../hooks/useSearchHook";

const docProgessPercentage = {
  signed: 100,
  viewed: 60,
  sent: 30,
  draft: 10,
};

function ActionAreaCard(props) {
  const { docData, handleCardClick } = props;

  let docStatus = (docData.signedOn)?"done":"pending";
  let docProgress = (docData.signedOn)?"signed":(docData.viewedOn)?"viewed":"sent";;

  return (
    <Card sx={{ maxWidth: "100%" }} onClick={()=>{handleCardClick(docData._id)}}>
      <CardActionArea>
        <CardContent>
          <div style={{ display: "flex" }}>
            <div style={{ width: "70%", padding: "2%", position: "relative" }}>
              <Typography gutterBottom variant="h5" component="div">
                {docData.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Signer : Sarat, E
              </Typography>

              <div className="doc-status">
                {docStatus == "done" ? (
                  <span className="completed-status">
                    <CheckCircleIcon fontSize={"large"} /> Completed
                  </span>
                ) : (
                  <span className="pending-status">
                    <PendingActionsIcon fontSize={"large"} /> Pending
                  </span>
                )}
              </div>
              <div className="document-progress">
                {docProgress + " on 10-03-2032"}
                <LinearProgress
                  color={docStatus == "done" ? "success" : "primary"}
                  variant="determinate"
                  value={docProgessPercentage[docProgress]}
                />
              </div>
            </div>
            <div style={{ width: "150px", height: "200px" }}>
              <img
                width="100%"
                height="100%"
                src="https://meetingtomorrow.com/wp-content/uploads/2019/08/TextDocument.png"
              />
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


const actionButtons = [
  { name: 'Create Document', variant:'contained', path: "/selectflow" },
  { name: 'Templates', variant:'outlined', path: "/templates" },
  { name: 'Signatures', variant:'outlined', path: "/" },
];
const styles = {
  buttonOutlined: {
    color: "blueviolet",
    border: "1px solid #8a2be285",
  },
  buttonContained: {
    background: "blueviolet",
  },
};



function DashboardLayout() {
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const [search, setSearch, handleSearchValue] = useSearch();

  const documentList = useSelector(
    (state) => state.documents.documentList
  );

  const handleCardClick = (documentID) => {
    navigate("/document/"+documentID)
  }

  const [authInfo, validateAuthToFallback, login, logOut] = useAuth();

  useEffect(() => {
    // logOut()
    validateAuthToFallback();
    console.log("authInfo",authInfo);

    let queryParams = {}
    if(search){
      queryParams.search = search;
    }
    dispatch(getAllDocuments(queryParams));
    // dispatch(getAllDocumentsOfAccount());
  }, [search]);




  return (
    <div className="document-dashboard-layout-page">
      <br />
      <Stack
        spacing={2}
        direction="row-reverse"
        style={{ padding: "0px 20px" }}
      >
        {actionButtons.map(action=>{
          return <Button variant={action.variant} 
            style={(action.variant=='contained')?styles.buttonContained:styles.buttonOutlined} 
            onClick={()=>navigate(action.path)}>{action.name}</Button>
        })}
      </Stack>

      <div className="search-input-bar-container">
        <input className="search-input-bar" placeholder="Search..." onChange={handleSearchValue} />
      </div>

       
      <Grid container spacing={3} style={{ padding: 20 }}>
        {documentList&&documentList.map((doc) => {
          return (
            <Grid item xs={12} md={4}>
              <ActionAreaCard docData={doc} handleCardClick={handleCardClick}/>
            </Grid>
          );
        })}
      </Grid>

      <SpeedDial />
    </div>
  );
}

export default DashboardLayout;
