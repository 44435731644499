import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentUploadData, setDocumentCreationRequestData } from "../../../actions/docflow/documentFlowAction";
import Button from "@mui/material/Button";
import LottiePlayer from "../../../components/LottiePlayer";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextField from '@mui/material/TextField';
import useInputField from "../../../hooks/useInputFieldHook";

const styles = {
  uploadContainer:{
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex'
  },
  uploadSectionContainer: {
    display: "flex",
    margin: "10px 0px"
  },
  selectedFileName: {
    padding: "2px",
    background: "lavender",
    padding: "2px",
    width: "300px",
    display: "flex",
    alignItems: "center",
  },
};

function UploadDocTemplate() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [titleValue, setTitleValue, handleTitleChange] = useInputField();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFileInputChange = (e) => {
    const fileData = e.target.files[0];
    const { name, type, size } = fileData;
    setSelectedFile(fileData);
    console.log(fileData);
  };

  const handlePdfUpload = () => {
    if (!selectedFile) {
      alert("Please select a file to upload!");
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(selectedFile);
    fileReader.onload = async () => {
      const pdfDataUrl = fileReader.result;
      console.log('pdfDataUrl', pdfDataUrl)
      dispatch(setDocumentUploadData(pdfDataUrl));
      dispatch(setDocumentCreationRequestData({title: titleValue.trim()}));
      let timeout = setTimeout(()=>{
        clearTimeout(timeout)
        navigate("/prepare");
      },300);
    };
  };

  return (
    <>
      <div className="upload-document-layout-page">
        <br />

        <div style={styles.uploadContainer}>
          <div style={styles.uploadContainer}>
          <h1 className="relative mt-12 z-10 text-6xl md:text-5xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
            Upload your Document
          </h1>

            <LottiePlayer
              animationData={
                "https://assets9.lottiefiles.com/packages/lf20_umoglgxj.json"
              }
              loop={true}
              style={{height: '350px'}}
            />
          </div>
          <br />
          <div>
            <TextField 
              style={{width:"400px"}} 
              value={titleValue}
              onChange={handleTitleChange}
              placeholder="Document Name" label="Document Name" variant="outlined" />
          </div>
          <br/>
          <div
            style={styles.uploadSectionContainer}
            className="upload-section-container"
          >
            <Button component="label">
              <AttachFileIcon /> Choose file
              <input
                hidden
                accept="application/pdf"
                type="file"
                onChange={handleFileInputChange}
              />
            </Button>

            <div style={styles.selectedFileName}>
              {selectedFile != null ? selectedFile.name : ""}
            </div>

          </div>
          {(titleValue && selectedFile !== null) && (
              <Button variant="contained" onClick={handlePdfUpload}>
                Upload File
              </Button>
            )}
        </div>
        
      </div>
    </>
  );
}

export default UploadDocTemplate;
