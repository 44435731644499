import React, {useEffect, useState} from 'react'
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import PrepareDocStepper from './PrepareDocStepper'
import LottiePlayer from '../../../components/LottiePlayer';
import * as animation from '../../../assets/lottiefiles/contract.json'
import { useNavigate } from "react-router-dom";
import { setSignersData as setSignersDataAction } from '../../../actions/docflow/documentFlowAction';
import { useDispatch, useSelector } from "react-redux";

import './styles.scss'

function AddSigners() {

    const [signers, setSigners] = useState({signer1:{name: "", email: ""}})
    const navigate = useNavigate();  
    const dispatch = useDispatch();



    const handleAddNewSigner = (e)=>{
        e&&e.preventDefault();
        const newSigner = {name: "", email: ""}
        setSigners(oldSigners=>{ 
            oldSigners["signer"+(Object.keys(oldSigners).length+1)]=newSigner
            return {...oldSigners}
        })
    }

    const handleChangeInput = (e) => {
        e&&e.preventDefault();
        const {id, name, value} = e.target;
        setSigners(oldSigners=>{ 
            oldSigners[id][name] = value;
            return {...oldSigners}
        })
    }

    const handleNextButton= () => {
        dispatch(setSignersDataAction(signers))
        navigate("/review")
    }
      
    useEffect(()=>{
        console.log('signersss',signers)
    },[signers])
    
    return (
        <div className='add-signers-layout-page'>
            <br /> 
            <PrepareDocStepper step={3}/>
            <br /><br /><br />
            <div className="add-signer-fields-container">
                
                <div style={{width: "30%", display:"flex", alignItems:"center"}}>
                    <LottiePlayer animationData = {animation} style={{width:'100%', height: '100%'}}/>
                </div>
                <div style={{width: "70%"}}>
                    <h2>Add Signers</h2>
                    <br /><br />
                    <Grid container spacing={3} style={{padding: "2%"}} onChange={handleChangeInput}>
                        {signers&&Object.keys(signers).map(signerKey=>{
                            let signer = signers[signerKey];
                            return <>
                                <Grid item xs={12} md={4}>
                                    <TextField id={signerKey} value={signer.name} style={{width:"100%",margin: "0% 0"}} className='signer-name' label="Name" variant="outlined" name="name" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField id={signerKey} value={signer.email} style={{width:"100%",margin: "0% 0"}} className='signer-email' label="Email" variant="outlined" name="email" />
                                </Grid>
                            </>
                        })}
                    </Grid>
                    <br />
                    <div>
                        <Button onClick={handleAddNewSigner} style={{width: "150px", margin: "20px"}} variant="outlined">Add Another</Button>

                        <Button onClick={handleNextButton} style={{width: "150px", margin: "20px",  background:"blueviolet"}} variant="contained">Next</Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default (AddSigners)
