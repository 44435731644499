import React, { useEffect } from "react";
import TemplateCard from "./bricks/TemplateCard";
import FolderCard from "./bricks/FolderCard";
import Grid from "@mui/material/Grid";
import ActionButtonBar from "./bricks/ActionButtonBar";
import { getAllTemplates } from "./../../actions/api/template/templateAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/domain/useAuth";
// Single table for templates
const templates = [
  {
    _id:1,
    title: "Onboarding Employee",
    folder: "/",
    bookmarked: false,
  },
  {
    _id:3,
    title: "Health Document",
    folder: "/health/",
    bookmarked: false,
  },
  {
    _id:3,
    title: "Contact Document",
    folder: "/contracts/",
    bookmarked: false,
  }
];

function TemplatesLayout() {
  const currentFolder = "/";

  const templatesList = useSelector(
    (state) => state.templates.templatesList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [authInfo, validateAuthToFallback] = useAuth();


  const filteredTemplates = templates.filter(
    (template) => template.folder == currentFolder
  );

  const getFolders = () => {
    // This Part can be done in DB side when we fetch the documents in real time scenario
    // In DB use a wild card with "Like currentFolder % /""
    const filteredFolders = [
      ...new Set(
        templates
          .filter(
            (template) =>
              template.folder != currentFolder &&
              template.folder.startsWith(currentFolder)
          )
          .map((template) => {
            return template.folder
              .substring(currentFolder.length, template.folder.length)
              .split("/")[0];
          })
      ),
    ];
    return filteredFolders;
  };

  const filteredFolders = getFolders();

  useEffect(() => {
    validateAuthToFallback();
    // fetch templates
    dispatch(getAllTemplates());
  }, []);


  const handleTemplateClick = (templateID) => {
    navigate('/template/'+templateID)
  }

  return (
    <div className="templates-layout-page">
      <br />
      <ActionButtonBar />
      <Grid container spacing={3} style={{ padding: 10 }}>
        <Grid item xs={12} md={2}>
          <span
            style={{ fontSize: "12px", color: "grey", padding: "0px 10px" }}
          >
            FOLDERS
          </span>
          <Grid container spacing={1} style={{ padding: 20 }}>
            {filteredFolders.map((folder) => {
              return (
                <Grid item xs={6} md={6}>
                  <FolderCard title={folder} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} md={10}>
          <div style={{ boxShadow: "1px solid black" }}>
            <span
              style={{ fontSize: "12px", color: "grey", padding: "0px 20px" }}
            >
              TEMPLATES
            </span>

            <Grid container spacing={3} style={{ padding: 20 }}>
              {(templatesList.length!=0)&&templatesList.map((template) => {
                return (
                  <Grid item xs={12} md={4}>
                    <TemplateCard title={template.title} handleTemplateClick={()=>{handleTemplateClick(template._id)}}/>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default TemplatesLayout;
