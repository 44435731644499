import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setPreparationFlowData } from '../../actions/docflow/documentFlowAction';
// sign-yourself || send-to-others || create-template || use-template

function usePreparationFlow() {
    const dispatch = useDispatch();
    const dispatchAction= (...args) =>{
        return dispatch(setPreparationFlowData(...args))
    }
    const preparationFlowData = useSelector(
      (state) => state.docflow.preparationFlowData
    );
    return [preparationFlowData, dispatchAction]
}

export default usePreparationFlow