export function setDocumentUploadData(payload) {
  //uploads selected document
  return { type: "SET_DOCUMENT_UPLOAD_DATA", payload };
}

export function setPreparationFlowData(payload) {
  // sign-yourself || send-to-others || create-template || use-template
  return { type: "SET_PREPARTION_FLOW", payload };
}

export function setDocumentCreationRequestData(payload) {
    return { type: "SET_DOCUMENT_CREATION_REQUEST_DATA", payload };
}

export function setSignersData(payload) {
  return { type: "SET_SIGNERS_DATA", payload };
}

export function setEmailBodyData(payload) {
  return { type: "SET_EMAIL_BODY_DATA", payload };
}
