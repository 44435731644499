import React, {useEffect, useState} from 'react'
import './styles.scss'
import { useDispatch, useSelector } from "react-redux";
import './styles.scss';
import { useNavigate } from "react-router-dom";
import usePreparationFlow from "../../../hooks/domain/usePreparationFlow";
import { PREPARATION_FLOW } from '../../../utils/constants';
import { PinContainer } from '../../../components/ThirdParty/Aceternity/ThreeDPinCard';
import NotYetLiveModal from '../../../components/common/NotYetLiveModal/NotYetLiveModal';
import useDialogHook from '../../../hooks/useDialogHook';


const flows = [
    {id:"sign_yourself", title: "Sign Yourself", description: "Fill form fields, add text and draw your signature.", preparationFlow:PREPARATION_FLOW.SIGN_YOURSELF, path: "/upload"},
    {id:"send_others_to_sign", title: "Send to Others for Sign", description: "Add Signers, mark where to fill and sign, send it out and mark progress.", preparationFlow:PREPARATION_FLOW.SEND_TO_OTHERS, path: "/upload"}

]


function SelectFlowType(props) {

  const navigate = useNavigate();  
  const [isModalOpen, handleModalOpen, handleModalClose] = useDialogHook();

    const [preparationFlowData, dispatchPreparationFlow] = usePreparationFlow();
    const useFromTemplateData = useSelector(
      (state) => state.templates.useTemplateData
    );

    const handleClickAction = (flowData) => {
      // BEFORE_PUBLISH
      if(flowData.id=="send_others_to_sign"){
        handleModalOpen();
        return;
      }

      dispatchPreparationFlow(flowData.preparationFlow)
      if(useFromTemplateData){
        navigate('/prepare');
      }else{
        navigate(flowData.path)
      }  
    }

  return (
      <div className='select-flow-type-layout-page'>
          <br /><br />
          <h1 className="relative mt-12 z-10 text-6xl md:text-6xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
            Who needs to fill and sign?
          </h1>

          <div className="w-full h-full flex items-center justify-center overflow-hidden">
              <div className="sm:h-full md:h-[40rem] flex flex-col sm:flex-row w-full items-center justify-center">
              {
                flows&&flows.map(flow=>{
                        return <div className="m-6" onClick={()=>handleClickAction(flow)}>
                            <PinContainer
                            title={flow.title}
                            // href="https://bocument.com/"
                            >
                            <div className="mt-4 mb-4">
                              <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[16rem] h-[18rem] md:w-[20rem] md:h-[20rem]">
                              <h3 className="max-w-xs !pb-2 !m-0 font-bold text-xl text-base text-slate-100">
                                  {flow.title}
                              </h3>
                              <div className="text-base !m-0 !p-0 font-normal">
                                  <span className="text-slate-400 ">
                                      {flow.description}
                                  </span>
                              </div>
                              <br/>
                                  <div className="flex flex-1 w-full rounded-lg sm:mt-3 md:mt-4 bg-gradient-to-br from-violet-500 via-purple-500 to-blue-500" />
                              </div>
                            </div>
                        </PinContainer>
                    </div>
                    })
                }
              </div>
           </div>

           <NotYetLiveModal 
            isOpen={isModalOpen}
            onClose={() => handleModalClose()}
            />
      </div>
  )
}


export default (SelectFlowType)
