export const PREPARATION_FLOW = {
    PREPARE_DOCUMENT: "prepare-document",
    SIGN_YOURSELF : "sign-yourself",
    SEND_TO_OTHERS : "send-to-others",
    CREATE_TEMPLATE : "create-template",
    USE_TEMPLATE : "use-template",
    SIGNED_DOCUMENT : "signed-document",
    ADMIN_OPENED_DOCUMENT : "opened-document",
}

export const PAGE_FLOW = {
    EMPTY: null,
    PREPARE_DOCUMENT: "prepare",
    SIGN : "sign",
    SIGNED : "signed",
    USE_TEMPLATE : "use-template",
}