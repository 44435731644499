import React from "react";
import "../styles.scss";
import Button from "@mui/material/Button";

const styles = {
  fieldButton: {
    margin: "5px 10px",
    width: "200px",
    background: "white",
  },
};

// function FieldSideBarButton(props) {
//   const { label, handleClick } = props;

//   return <Button style={styles.fieldButton} variant="outlined" onClick={handleClick}>
//     {label}
//   </Button>;
// }

const FieldsViewSection = ({ label, children }) => (
  <div className="bg-gray-700 p-4 rounded-lg">
    <div className="space-y-3">
      <h4 className="text-md font-semibold mb-2">{label}</h4>
      <div className="grid grid-cols-2 gap-4">
            {children}
      </div>
    </div>
  </div>
);

FieldsViewSection.defaultProps = {};

export default FieldsViewSection;
