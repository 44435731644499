import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/domain/useAuth";
import LottiePlayer from "../../components/LottiePlayer";
import * as animation from "./../../assets/lottiefiles/document_lock.json";

const LoginPage = () => {
  const navigate = useNavigate();
  const [authInfo, validateAuthToFallback, logIn, logOut] = useAuth();
  const [email, setEmail] = useState("user@user.com");
  const [password, setPassword] = useState("useruser");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Implement login logic here
    // Send request to the server
    const response = await axios.post("/login", {
      email: email,
      password: password,
    });
    console.log("/login response.data", response.data);
    logIn({ ...response.data, isAuthenticated: true });
    navigate("/dashboard");
  };

  return (
    <>
      <div className="login-page">
        <div className="flex items-center justify-center min-h-screen bg-white-950">
            <div className="flex min-h-screen items-center" >
                <div style={{ width: "40%"}}>
                    <LottiePlayer
                    animationData={animation}
                    loop={true}
                    style={{ width: "100%", height: "100%" }}
                    />
                </div>
                <div
                    className="p-12 bg-white shadow-lg rounded-lg"
                    style={{ width: "60%", height: "100%" }}
                >
                    <h1 className="text-4xl font-bold text-center text-gray-700 mb-2">
                    Login
                    </h1>
                    <p className="text-m text-center text-gray-500 mb-6">
                    Let’s unlock your signature style
                    </p>
                    <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <input
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-4 text-sm text-gray-700 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        required
                        />
                    </div>
                    <div>
                        <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-4 text-sm text-gray-700 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full px-4 py-4 text-sm font-medium text-white bg-violet-600 rounded-lg hover:bg-neutral-700"
                    >
                        Sign In
                    </button>
                    </form>
                    {/* <div className="mt-6 text-sm text-center text-gray-500">
                            New here? <a href="/signup" className="text-blue-600 hover:underline">Create an account</a>
                        </div>
                        <div className="mt-2 text-sm text-center text-gray-500">
                            Forgot your <a href="/reset-password" className="text-blue-600 hover:underline">password?</a>
                        </div> */}
                </div>
            </div>
        </div>
        <h4 className=" flex flex-row-reverse m-r-12 m-t-12 text-3xl font-extrabold text-violet-600">
            bocument.com
        </h4>
      </div>
    </>
  );
};

export default LoginPage;
