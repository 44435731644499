import React, { useEffect, useState } from "react";
import ResizeDragComp from "./../../ResizeDragComp";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedComponentData } from "../../../actions/docmaker/docmakerAction";
import "./index.css";

function BlockWrapperComponent(props) {
  const { blockText, componentId, width, height, x, y} = props;
  const dispatch = useDispatch();
  const selectedComponentData = useSelector(
    (state) => state.docmaker.selectedComponentsData
  );

  const updateComponentData = (componentId, data) => {
    console.log("componentId", componentId);
    console.log("data", data);
    dispatch(setSelectedComponentData(data));
  };

  const handleDragStop = function (e, d) {
    e.preventDefault();
    console.log("d axises", d);
    let { x, y } = d;
    let comp = { ...selectedComponentData[componentId], x, y };
    updateComponentData(componentId, comp);
  };

  const handleResizeStop = function (e, direction, ref, delta, position) {
    e.preventDefault();
    console.log("position", position);
    console.log("ref", ref);
    let { x, y } = position;
    let { width, height } = ref.style;
    let comp = { ...selectedComponentData[componentId], x, y, width, height };
    updateComponentData(componentId, comp);
  };

  return (
    <>
      <ResizeDragComp
        key={componentId}
        x={x}
        y={y}
        handleDragStop={handleDragStop}
        handleResizeStop={handleResizeStop}
      >
        <div className="block-content">{blockText}</div>
      </ResizeDragComp>
    </>
  );
}

BlockWrapperComponent.defaultProps = {
  width: 200,
  height: 30,
  x: 0,
  y: 0,
};

export default BlockWrapperComponent;
