import axios from "axios";
import { setTemplatesData, setTemplateData, clearTemplateData as clearTemplateDataAction } from "../../templates/templates";

export const getAllTemplates = () => async dispatch => {
  try {
    const response = await axios.get("/templates");
    dispatch(setTemplatesData(response.data))
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTemplateByID = (templateId) =>  async dispatch => {
  try {
    const response = await axios.get(`/templates/${templateId}`);
    dispatch(setTemplateData(response.data))
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const clearTemplateData = () =>  async dispatch => {
  dispatch(clearTemplateDataAction());
};

// Function to create a template
export const createTemplateWithPDF = async (templateData) => {
  try {
    const formData = new FormData();
    for (const key in templateData) {
      if (key === "documentPDF") {
        formData.append(key, templateData[key], templateData[key].name);
      } else {
        formData.append(key, JSON.stringify(templateData[key]));
      }
    }

    const response = await axios.post("/templates", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to create template", error);
  }
};



export const saveTemplate = async (templateData) => {
  try {
    const response = await axios.post("/savetemplate", templateData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to create template");
  }
};

export const createTemplate = async (templateData) => {
  try {
    const response = await axios.post("/templates", templateData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to create template");
  }
};

export const updateTemplate = async (templateId, updatedTemplateData) => {
  try {
    const response = await axios.put(
      `/templates/${templateId}`,
      updatedTemplateData
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};

export const deleteTemplate = async (templateId) => {
  try {
    const response = await axios.delete(`/templates/${templateId}`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
    throw error;
  }
};
