import React, { useState } from 'react'
import { FaTrash } from "react-icons/fa";
import IconButton from '@mui/material/IconButton';

function DeleteCompButton(props){
  const {componentId, handleDeleteComponent} = props

    return <>
            <div className='component-field-icon'>
              <IconButton color="primary" onClick={handleDeleteComponent(componentId)}>
                <FaTrash className="delete-field-icon"/>
              </IconButton>
            </div>
    </>
}


export default DeleteCompButton

