import * as React from "react";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import FolderImage from "../../../assets/images/folder.png";

export default function FolderCard(props) {
    const { title } = props
  return (
    <Card style={{ boxShadow: "none" }}>
      <CardActionArea>
        <div style={{ width: "100%"}}>
          <img
            src={props.folderImage}
            style={{ width: "100%"}}
          />
          <p style={{ margin: "0px", textAlign: "center", fontSize: "16px", textTransform: "capitalize" }}>
            {title}
          </p>
        </div>
      </CardActionArea>
    </Card>
  );
}

FolderCard.defaultProps = {
  folderImage: FolderImage,
};
