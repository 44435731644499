import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";

const actions = [
  { icon: <FileCopyIcon />, name: 'Create Document', path: "/selectflow" },
  { icon: <AddIcon />, name: 'Create Template', path: "/prepare" },
  { icon: <PrintIcon />, name: 'Print', path: "/prepare" },
  { icon: <ShareIcon />, name: 'Share', path: "/prepare" },
];

export default function BasicSpeedDial() {
  const navigate = useNavigate();  

  return (
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 40, right: 30, fontSize: 50 }}
        FabProps={{size:'large'}}
        icon={<EditIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            FabProps={{size:'large'}} 
            sx={{
              bgcolor: 'purple',
              color: 'white'
            }}
            onClick={()=>navigate(action.path)}
          />
        ))}
      </SpeedDial>
  );
}