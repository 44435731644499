import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";

export default function NotYetLiveModal({ isOpen, onClose }) {

  const navigate = useNavigate();  

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex items-center justify-center p-4 overflow-auto"
      >
        <Box className="bg-white rounded-lg shadow-xl m-auto p-4"
          style={{
            width: '90%',
            maxWidth: '600px',  // Ensures the modal isn't too wide on desktop
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '50%',
            left: '50%'
          }}
        >
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 flex items-center">
                  🚀 <span className="ml-2">Unlock Early Access!</span>
              </h2>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                  <span className="text-3xl">×</span>
              </button>
            </div>
            <hr className="my-4"/>
            <div className="text-gray-600">
                <p>Early adopters gain:</p>
                <ul className="list-disc pl-5 space-y-2">
                    <li><strong>Exclusive Access:</strong> Be the first to experience and leverage our new, cutting-edge feature.</li>
                    <li><strong>Custom Solutions:</strong> Whether it's full service, self-hosted, or as a seamless component - we cater to your unique needs.</li>
                    <li><strong>Partnership Opportunities:</strong> Collaborate directly with our team for customized integration and early benefits.</li>
                </ul>
            </div>
            <div className="flex justify-center mt-4 space-x-4">
                <button
                  className="bg-violet-600 hover:bg-violet-800 text-white font-bold py-2 px-6 rounded-lg transition duration-150"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/about#contactsales');
                  }}
                >
                  Connect with Sales
                </button>

                <button
                    className="bg-violet-600 hover:bg-violet-800 text-white font-bold py-2 px-6 rounded-lg transition duration-150"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
