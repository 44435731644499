
export function setSelectedComponentData(payload) {
    //updates single component
    return { type: 'SET_SELECTED_COMPONENTS_DATA', payload }
}

export function setAllComponentsData(payload) {
    //updates all components
    return { type: 'SET_ALL_SELECTED_COMPONENTS_DATA', payload }
}

export function deleteComponentDataById(payload) {
    //updates all components
    return { type: 'DELETE_COMPONENT_DATA_BY_ID', payload }
}

export function clearAllSelectedComponentsData(payload) {
    //updates all components
    return { type: 'DELETE_ALL_SELECTED_COMPONENTS_DATA', payload }
}
