import React, { useState } from 'react'
import ResizeDragComp from './../../ResizeDragComp';
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedComponentData, setAllComponentsData } from '../../../actions/docmaker/docmakerAction';
import useDialogHook from '../../../hooks/useDialogHook';
import SignatureModal from './SignatureModal';
import DeleteCompButton from '../UtilityComponents/DeleteCompButton';

/** 
 * https://github.com/agilgur5/react-signature-canvas
*/


function SignatureComponent(props){
  const {componentId, x, y, width, height, isAnnotation, flow, value, handleDeleteComponent} = props
  const dispatch = useDispatch();
  const selectedComponentsData = useSelector((state) => state.docmaker.selectedComponentsData)
  const componentData = selectedComponentsData[componentId]
  const [isOpen, handleDialogOpen, handleDialogClose] = useDialogHook()

  const updateComponentData = (componentId, data) => {
    console.log('componentId',componentId)
    console.log('data',data)
    dispatch(setSelectedComponentData(data))
  }

  const handleDragStop = function(e, d){
    e.preventDefault();
    let {x, y} = d
    let comp = {...selectedComponentsData[componentId], x, y, width, height}
    updateComponentData(componentId, comp)
  }

  const handleResizeStop = function(e, direction, ref, delta, position){
      e.preventDefault();
      console.log("position", position);
  }

  const updateSignatureDataByComponentID = function(componentId, data){
    let resultData = {...selectedComponentsData}
    let value = data
    let IS_SENDER_FLOW = (flow=="prepare");
    console.log("----selectedComponentsData", selectedComponentsData)
    console.log("----componentId", componentId)
    let isSignatureComponent = (selectedComponentsData[componentId].type == "SignatureComponent")
    let isAnnotation = selectedComponentsData[componentId].isAnnotation
    if(isSignatureComponent&&isAnnotation==IS_SENDER_FLOW){
      resultData[componentId]= {...selectedComponentsData[componentId], value}
    }else{
      resultData[componentId]= {...selectedComponentsData[componentId]}
    }

    dispatch(setAllComponentsData(resultData))
  }

  const updateAllSignaturesData = function(data){
    let resultData = {}
    let value = data
    let IS_SENDER_FLOW = (flow=="prepare");

    Object.keys(selectedComponentsData).forEach(cid=>{
        let isSignatureComponent = (selectedComponentsData[cid].type == "SignatureComponent")
        let isAnnotation = selectedComponentsData[cid].isAnnotation
        if(isSignatureComponent&&isAnnotation==IS_SENDER_FLOW){
          resultData[cid]= {...selectedComponentsData[cid], value}
        }else{
          resultData[cid]= {...selectedComponentsData[cid]}
        }
      })
    dispatch(setAllComponentsData(resultData))
  }

  const updateSignatureValue = function(data){
    // updateAllSignaturesData(data)
    updateSignatureDataByComponentID(componentId, data)
  }

  const isAnnotationInSignerFlow= (flow=='sign'&&isAnnotation) || false
  const isSignerFlow= (flow=='sign');
  const isSignedFlow = flow == "signed";
  const isOnlyShowFilledData = isAnnotationInSignerFlow || isSignedFlow;
  const signatureData = selectedComponentsData&&selectedComponentsData[componentId]?.value || undefined
  return <>
        <ResizeDragComp key={componentId} 
              x={x} y={y} width={width} height={height} 
              styles={isOnlyShowFilledData?{background:"transparent", border:"transparent"}:{}}
              enableResizing={!isAnnotation}
              handleDragStop={handleDragStop} handleResizeStop={handleResizeStop}>
            <div className="resizable-drag-component">
            {(!isSignerFlow&&!isSignedFlow)&&<DeleteCompButton 
                componentId={componentId} 
                handleDeleteComponent={handleDeleteComponent}
            />}
            
            <div onClick={handleDialogOpen}>
                {signatureData?<div className='existing-signature'>
                  <img style={{width:width, height:height, margin:'4px'}} src={signatureData} alt="Red dot" />
                </div>:
                <div className='no-signature'>
                  <p>Draw Signature</p>
                </div>}
            </div>
            </div>
        </ResizeDragComp>

        {isOpen&&<>
            <SignatureModal 
                handleDialogClose={handleDialogClose} 
                updateSignatureValue={updateSignatureValue}
                isOpen={isOpen} width={width} height={height} />
        </>}
    </>
}

SignatureComponent.defaultProps = {
  x : 0,
  y: 0,
  width: 200,
  height: 70,
  isAnnotation: false,
  flow: 'prepare',
  value: ""
}

export default SignatureComponent