
export const convertNodetoBrowserBuffer = (nodeBuffer) => {
    const arrayBuffer = new Uint8Array(nodeBuffer.data);
    return arrayBuffer;
};


export const downloadFileFromBrowser = (url,filename) => {
    // download a file from browser
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};

export const downloadBufferAsFile = async (buffer, filename='document.pdf', mimeType='application/pdf')=>  {
    const arrayBuffer = convertNodetoBrowserBuffer(buffer);
    const blob = new Blob([arrayBuffer], { type: mimeType });
    const url = URL.createObjectURL(blob);
    downloadFileFromBrowser(url, filename);   
}



