import React, { useEffect } from "react";
import DocMaker from "../../DocMaker/DocMaker";
import { useDispatch, useSelector } from "react-redux";
import samplePDF from "./../../../components/PdfLoader/contract-examples/rental-agreement-room.pdf";
import { createTemplate, getTemplateByID, deleteTemplate, clearTemplateData } from "../../../actions/api/template/templateAction";
import { setUseTemplateData } from "../../../actions/templates/templates";
import { PREPARATION_FLOW } from "../../../utils/constants";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FieldSideBarButton from "./../../DocMaker/components/FieldSideBarButton"
import FieldsViewSection from "../../DocMaker/components/FieldsViewSection";
import useAuth from "../../../hooks/domain/useAuth";

function SideBarViewData(props){
  const navigate = useNavigate();  
  const dispatch = useDispatch();

  const handleDeleteTemplate = ()=> {
    let templateID = props.documentInfoData._id;
    deleteTemplate(templateID)
    navigate("/templates")
  }

  const handleUseTemplate = ()=> {
    let {_id, ...templateData} = props.documentInfoData;
    dispatch(setUseTemplateData({...templateData}))
    // navigate("/prepare")
    navigate("/selectflow")
  }

  return <>
    <>
      <h2>{props?.documentInfoData?.title}</h2>
      {(props.IS_USE_TEMPLATE)&&<>
        <FieldsViewSection label={"Views"}>
          <FieldSideBarButton handleClick={()=>navigate("/signed")} label="Signed View" />
          <FieldSideBarButton handleClick={()=>handleDeleteTemplate()} label="Delete Template" />
          <FieldSideBarButton handleClick={()=>navigate("/template/id")} label="Update Template" />
          <FieldSideBarButton handleClick={()=>handleUseTemplate()} label="Use Template" />
        </FieldsViewSection>      
        </>}
    </>
  </>
}


function TemplateLayout() {
  const dispatch = useDispatch();
  const { id: templateID } = useParams();
  const templateData = useSelector(
    (state) => state.templates.templateData
  );

  const [authInfo, validateAuthToFallback] = useAuth();

  useEffect(()=>{
    validateAuthToFallback();
    dispatch(getTemplateByID(templateID))
  },[])

  useEffect(()=>{
    //unload
    return ()=>{
      dispatch(clearTemplateData())
    }
  },[])

  return (
    <div className="template-layout-page">
      {/* <h3>{templateData.title}</h3> */}
      {templateData&&<DocMaker
        flow="template"
        preparationFlow={PREPARATION_FLOW.USE_TEMPLATE}
        pdfFileDocumentData={templateData.documentPDFURL||samplePDF}
        initialDocumentFieldsData={{...templateData.documentFieldsData}}
        CustomSideBarFieldsComponent={SideBarViewData}
        documentInfoData={templateData}
      />}
      <br />
      <br />
    </div>
  );
}

export default TemplateLayout;
