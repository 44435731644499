import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {downloadBufferAsFile} from "./../../utils/document/documentUtil"
import useFileUpload from './../../hooks/useFileUploadHook'
import UploadDragFile from './../../components/UploadDocument/UploadDragFile'
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import { MdContentCopy } from 'react-icons/md';

function TimeStampLayout() {

  const [selectedFile, handleFileInputChange, fileDetails] = useFileUpload();
  const [timestampData, setTimestampData] = useState({isTimestampValid:false});
  const hashRef = useRef(null);
  const timestampRef = useRef(null);

  const copyToClipboard = (ref) => {
      if (ref.current) {
          navigator.clipboard.writeText(ref.current.textContent).then(() => {
              console.log('Text copied to clipboard!');
          }, (err) => {
              console.error('Failed to copy text: ', err);
          });
      }
  };


  const handleFileUploadSubmit = async (uploadedFile) => {
    if(!uploadedFile){
        console.log('No File Uploaded...');
        return;
    }
    const formData = new FormData();
    formData.append('document', uploadedFile);

    try {
        const response = await axios.post(`/timestamp`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        downloadBufferAsFile(response.data.timestampedDocumentBuffer);
        setTimestampData({...response.data});
        return response.data; // This will include encryptedHash, timestamp, and message
        
    } catch (error) {
        console.error('Error in timestamping document:', error.response ? error.response.data : error.message);
        throw error;
    }
  };


    return (
      <div className="timestamp-layout-page mx-auto">
        <br /> <br />
        <h1 className="relative mt-12 z-10 text-6xl md:text-6xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
            Document Timestamp
        </h1>

        <div className="min-h-screen py-8">
            <div className="container mx-auto max-w-6xl bg-white rounded-lg shadow-lg p-4">                
                <div>
                    <h2 className="text-xl font-semibold text-gray-700 mb-2">Upload Document</h2>
                    <UploadDragFile handleFileInputChange={handleFileInputChange} fileDetails={fileDetails} />
                    <div className="flex justify-center">
                        <button
                            className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                            onClick={() => handleFileUploadSubmit(selectedFile)}
                        >
                            Timestamp Document
                        </button>
                    </div>
                    
                </div>
                <br /> <br /> 
                {timestampData?.encryptedHash && (
                    <div className="mt-2 p-2 bg-gray-100 rounded">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">Timestamp Results</h3>
                        <div className="relative p-3 border rounded bg-gray-50 max-w-full">
                            <div className="flex justify-between items-start">
                                <div className="overflow-auto p-3 border rounded bg-gray-50 max-w-full">
                                    <strong>Encrypted Hash:</strong>
                                    <p className="whitespace-pre-wrap text-sm" ref={hashRef}>{timestampData.encryptedHash}</p>
                                </div>
                                <button
                                    className="ml-2 p-1 rounded-full text-gray-500 hover:text-gray-700 focus:outline-none focus:bg-gray-300"
                                    onClick={() => copyToClipboard(hashRef)}
                                    aria-label="Copy hash"
                                >
                                    <MdContentCopy size={24} />
                                </button>
                            </div>
                            
                            <div className="flex justify-between items-start mt-4">
                                <div className="overflow-auto p-3 border rounded bg-gray-50 max-w-full">
                                    <strong>Timestamp:</strong>
                                    <p className="whitespace-pre-wrap text-sm" ref={timestampRef}>{timestampData.timestamp}</p>
                                </div>
                                <button
                                    className="ml-2 p-1 rounded-full text-gray-500 hover:text-gray-700 focus:outline-none focus:bg-gray-300"
                                    onClick={() => copyToClipboard(timestampRef)}
                                    aria-label="Copy timestamp"
                                >
                                    <MdContentCopy size={24} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>

      </div>
    );
}

export default TimeStampLayout;
