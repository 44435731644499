import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {downloadBufferAsFile} from "./../../utils/document/documentUtil"
import useFileUpload from './../../hooks/useFileUploadHook'
import UploadDragFile from './../../components/UploadDocument/UploadDragFile'
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';


const DocumentValidationMessage = (props) => {
  const {timestampData} = props;
  let message = '';
  let messageClass = '';
  // Check if timestampData exists and has a defined isTimestampValid property
  if (!timestampData || timestampData.isTimestampValid === null) {
      return null; // Do not render anything if the status is null or undefined
  }
  if(timestampData.isTimestampValid =='error'){
    message =  'The timestamp and encrypted hash is invalid for the file. The file is tampered or not the correct file..';
    messageClass = "bg-red-100 text-red-800";
  }

  if (timestampData.isTimestampValid == true) {
      // Render this paragraph if the timestamp is valid
      message = 'The timestamp and encrypted hash is valid for the file. The file is not tampered and is original.';
      messageClass = "bg-green-100 text-green-800";

  } else if(timestampData.isTimestampValid == false){
      // Render this paragraph if the timestamp is invalid
      message = 'The timestamp and encrypted hash is invalid for the file. The file is tampered or not the correct file.';
      messageClass = "bg-red-100 text-red-800";
    }

  return <>
     <div className={`p-4 m-6 rounded-lg ${messageClass} border-l-4 shadow-md`}>
      <p className="font-semibold text-lg md:text-xl text-center">{message}</p>
    </div>
  </>
};

function ValidateTimestampLayout() {

  const [selectedFile, handleFileInputChange, fileDetails] = useFileUpload();
  const [timestampData, setTimestampData] = useState({isTimestampValid:null});


  const handleTimestampDataChange = (e) =>{
    e.preventDefault();
    const { name, value } = e.target;
    setTimestampData({...timestampData, [name]:value })
  }


  const handleTimestampValidationSubmit = async (uploadedFile, timestampInfo) => {
    const formData = new FormData();
    formData.append('document', uploadedFile);
    formData.append('encryptedHash', timestampInfo.encryptedHash);
    formData.append('timestamp', timestampInfo.timestamp);


    try {
        const response = await axios.post(`/validate`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        setTimestampData({...timestampData, isTimestampValid: response.data.isValid});
        return response.data;
        
    } catch (error) {
        console.error('Error in timestamping document:', error.response ? error.response.data : error.message);
        setTimestampData({...timestampData, isTimestampValid: 'error'});
        throw error;
    }
  };

    return (
      <div className="timestamp-layout-page mx-auto max-w-6xl p-2">
        <h1 className="relative mt-12 z-10 text-6xl md:text-6xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
            Validate Timestamp
        </h1>
        <br /> <br />
        <div className="validate-the-timestamp">
            <UploadDragFile handleFileInputChange={handleFileInputChange} fileDetails={fileDetails} />
            <br />
            <TextField 
              name="encryptedHash"
              style={{width:"100%"}} 
              onChange={handleTimestampDataChange}
              placeholder="Encrypted Hash" label="Encrypted Hash" variant="outlined" />
            <br /><br />
            <TextField 
              name="timestamp"
              style={{width:"100%"}} 
              onChange={handleTimestampDataChange}
              placeholder="Timestamp" label="Timestamp" variant="outlined" />
            <br />
            <br />
            <div className="flex justify-center">
                <button
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                    onClick={() => handleTimestampValidationSubmit(selectedFile, timestampData)}
                >
                    Validate Timestamp
                </button>
            </div>

            <div className='validate-timestamp-result'>
              {timestampData && <DocumentValidationMessage timestampData={timestampData}/>}
            </div>
            <br />
        </div>


        

      </div>
    );
}

export default ValidateTimestampLayout;
