import React, { useEffect, useState } from "react";

function UploadDragFile(props) {
  const {fileDetails, handleFileInputChange} = props;
  
  return (
    <>
      <div className="upload-document-layout-page">
        <div className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-lg hover:border-blue-500 transition-colors">
            <label className="flex flex-col items-center justify-center text-gray-700 cursor-pointer">
                <span className="mt-2 text-base leading-normal">
                    {fileDetails&&fileDetails.name || 'Select a file or drag here'}
                </span>
                <input type='file' className="hidden" onChange={handleFileInputChange} />
            </label>
            {fileDetails&&fileDetails.name && <div className="mt-2 text-sm text-gray-500">File: {fileDetails&&fileDetails.name}</div>}
        </div>
      </div>
    </>
  );
}

export default UploadDragFile;
