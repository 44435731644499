export function setTemplatesData(payload) {
    return { type: 'SET_TEMPLATES_DATA', payload }
}

export function setTemplateData(payload) {
    return { type: 'SET_TEMPLATE_DATA', payload }
}

export function clearTemplateData() {
    return { type: 'CLEAR_TEMPLATE_DATA' }
}


// USE TEMPLATE to PREPARE DOCUMENT
export function setUseTemplateData(payload) {
    return { type: 'SET_USE_TEMPLATE_DATA', payload }
}
export function clearUseTemplateData() {
    return { type: 'CLEAR_USE_TEMPLATE_DATA' }
}