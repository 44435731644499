import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalStorage } from "../useLocalStorage";
import { useNavigate } from "react-router-dom";
function useAuth() {
  const [authInfo, setAuth] = useLocalStorage("auth");
  const navigate = useNavigate();
  const logIn = (data={}) => {
    setAuth({...data, isAuthenticated: true});
  };
  const logOut = () => {
    navigate('/login')
  };
  
  const validateAuthToFallback = () => {
    if(!(authInfo&&authInfo.isAuthenticated)){
        navigate('/login')
      }
  }
  return [authInfo, validateAuthToFallback, logIn, logOut];
}

export default useAuth;
