import React, { useState } from 'react'
import TextComponent from '../TextComponent';
import BlockWrapperComponent from '../BlockWrapperComponent';
import DateComponent from '../DateComponent';
import SignatureComponent from '../SignatureComponent';

function DocComponentFactory(componentType){
    switch(componentType) {
        case 'TextComponent':
          return TextComponent;
        case 'DateComponent':
            return DateComponent;
        case 'SignatureComponent':
            return SignatureComponent;
        case 'BlockWrapperComponent':
            return BlockWrapperComponent;
        default:
          return <></>;
      }
}


export default DocComponentFactory