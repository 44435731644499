import React, { useState, useEffect } from 'react';
import './LandingPage.css'
import { useNavigate } from 'react-router-dom';
import { cn } from '../../utils/third-party/aceternity/cn';
import BackgroundBoxTiles from '../../components/ThirdParty/Aceternity/BackgroundBoxTiles';
import { HoverEffect } from '../../components/ThirdParty/Aceternity/HoverBorderCard';
import StickyScrollReveal from '../../components/ThirdParty/Aceternity/StickyScrollReveal';
// import { MaskContainer } from '../../components/ThirdParty/Aceternity/MaskContainer';
import { PinContainer } from '../../components/ThirdParty/Aceternity/ThreeDPinCard';
import BackgroundBeams from '../../components/ThirdParty/Aceternity/BackgroundBeams';
import { LampContainer } from '../../components/ThirdParty/Aceternity/LampEffect';
import { motion } from "framer-motion";

const scrollToSection = (sectionID) => {
    const section = document.getElementById(sectionID);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
}

const LandingBanner = (props) => {
    const {title, description} = props;
    const navigate = useNavigate();

    return (<>
        <section class="banner" id="banner" style={{background:"azure"}}>
            <div className="h-screen relative w-full overflow-hidden bg-slate-900 flex flex-col items-center justify-center">
            {/* Lamp Effect */}
            {/* <>
                <LampContainer>
                    <motion.h1
                        initial={{ opacity: 0.5, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{
                        delay: 0.3,
                        duration: 0.15,
                        ease: "easeInOut",
                        }}
                        className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center font-medium tracking-tight text-transparent"
                    >
                        <h1 className={("text-6xl md:text-9xl sm:text-8xl text-white relative z-20")}>{title}</h1>
                        <br /> 
                        <p className="sm:text-3xl md:text-3xl text-center mt-2 text-neutral-300 relative z-20">{description}</p>

                        <br/>
                        <div className="w-full px-5 py-3 z-20" >
                            <button 
                                className="bg-white dark:bg-white rounded-full w-fit text-black dark:text-black px-5 py-3 m-4 z-20" 
                                onClick={()=>scrollToSection('our_features')}
                            >
                                Know more
                            </button>

                            <button 
                                className="bg-white dark:bg-white rounded-full w-fit text-black dark:text-black px-5 py-3 m-4 z-20" 
                                onClick={()=>navigate('/')}
                            >
                                Start Now
                            </button>
                        </div> 
                    </motion.h1>
                </LampContainer>
            </> */}
            
            {/* BackgroundTiles */}
            <>
                <div className="absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />
            
                    <div className='desktop-only'><BackgroundBoxTiles /></div>
                    <h1 className={cn("text-6xl md:text-9xl sm:text-8xl text-white relative z-20")}>{title}</h1>
                    <p className="sm:text-3xl md:text-3xl text-center mt-2 text-neutral-300 relative z-20">{description}</p>
                    <br/>
                    <div className="w-fit px-5 py-3 z-20" >
                        <button 
                            className="bg-white dark:bg-white rounded-full w-fit text-black dark:text-black px-5 py-3 m-4 z-20" 
                            onClick={()=>scrollToSection('our_features')}
                        >
                            Know more
                        </button>

                        <button 
                            className="bg-white dark:bg-white rounded-full w-fit text-black dark:text-black px-5 py-3 m-4 z-20" 
                            onClick={()=>navigate('/')}
                        >
                            Start Now
                        </button>
                </div>
            </>
            </div>
        </section>
        </>
    );
};

const LandingWhyUsNew = (props) => {
 const projects = [
    {
      title: "Sign Yourself for Free",
      description:
        "Upload any document, use the fields, and download with ease.",
      link: "https://bocument.com",
    },
    {
      title: "Create and Send Documents",
      description:
        "Effortlessly create and send documents requiring signatures with just a few clicks.",
      link: "https://bocument.com",
    },
    {
      title: "Customize Documents",
      description:
        "Add signature, text, date fields, and more, tailored to your needs.",
      link: "https://bocument.com",
    },
    {
      title: "Electronically Sign Documents",
      description:
        "Securely sign documents from anywhere, at any time, on any device.",
      link: "https://bocument.com",
    },
    {
      title: "Send Signed Documents",
      description:
        "Instantly send signed documents to others directly via email.",
      link: "https://bocument.com",
    },
    {
      title: "Use Ready-made Templates",
      description:
        "Save time with pre-made templates easily customizable for your use.",
      link: "https://bocument.com",
    },
    {
      title: "Secure Document Storage",
      description:
        "Your documents are stored securely and encrypted for maximum protection.",
      link: "https://bocument.com",
    },
    {
      title: "Tamper Proof",
      description:
        "Supports timestamping and encryption mechanism to keep your documents tamperproof",
      link: "https://bocument.com",
    },
    {
      title: "Supports Account Level",
      description:
        "Manage multiple users and documents under one single account.",
      link: "https://bocument.com",
    },
    {
        title: "Integrate with your apps",
        description:
          "Integrate our component and use it within your apps.",
        link: "https://bocument.com",
    },
    {
        title: "Go paperless",
        description:
          "Go paperless, go digital and impress your customers and users",
        link: "https://bocument.com",
    },
    {
        title: "Days to Seconds",
        description:
          "Reduce your work processes and deliveribles from days to seconds.",
        link: "https://bocument.com",
    },
  ];
  

    return <>
    <section className="our_features w-full" id="our_features" style={{background:"white"}}>
        <div className="h-full w-full">
            <h1 className="relative w-full mt-12 z-10 text-lg md:text-5xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
                Packed with features designed for efficiency and ease.
            </h1>

            <div className="h-full w-full max-w-8xl mt-12 mx-auto px-16">
            <HoverEffect items={projects} />
            </div>
        </div>
    </section>
    </>
}


const GrowWithUs = (props) => {
    const content = [
    {
        title: "Onboard Employees in a Flash",
        description:
        "Welcome new team members effortlessly, getting them up and running in mere seconds",
        content: (
        <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
            Onboard Employees
        </div>
        ),
    },
    {
        title: "Accelerate & Seal Deals Swiftly",
        description:
        "Accelerate your sales cycle with lightning-fast eSignatures. A significant portion of business deals stall due to paperwork delays.",
        content: (
            <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
            Accelerate & Seal Deals Swiftly
        </div>
        ),
    },
    {
        title: "Automate Document Flows",
        description:
        "Let SmartSign handle the routing, reminders, and follow-ups. Spend less time managing paper and more time focusing on what matters - growing your business.",
        content: (
        <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--orange-500),var(--yellow-500))] flex items-center justify-center text-white">
            Automate Document Flows
        </div>
        ),
    },
    {
        title: "Reduce Operational Costs",
        description:
        "Cut down on printing, mailing, and storage costs. Investing in areas of your business that drive growth and innovation.",
        content: (
        <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
        Reduce Operational Costs
        </div>
        ),
    },
    {
        title: "Elevate Your Customer Experience",
        description:
        "Impress your customers with the ease and speed of SmartSign.providing a smooth, digital-first experience can set you apart from the competition and build lasting loyalty.",
        content: (
        <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
        Elevate Your Customer Experience
        </div>
        ),
    }
    ];

    return (
        <section class="grow-with-us" id="grow-with-us" style={{background:"rgb(15, 23, 42)"}}>
            <div className="p-5">
            <h1 className="relative z-10 mt-10 text-lg md:text-5xl  bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
                Grow with Us!
            </h1>
            <div className="mt-10">
                <StickyScrollReveal content={content} />
            </div>
            
            </div>
        </section>
    );

}



const LandingFunnyStory = (props) => {
    return (<>
        <section class="bg-white-200 text-gray-800">
            <div className="min-h-screen dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative p-12">
            {/* Radial gradient for the container to give a faded look */}
            <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
                <p className="text-4xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-500 to-neutral-800 py-10 pt-20 text-center mx-auto">
                    Less is More and Cheaper is Smarter!
                </p>

                <p className="text-lg px-4 md:text-lg lg:text-lg font-bold text-neutral-700 dark:text-white leading-relaxed lg:leading-snug text-center mx-auto">
                    Why settle for complex, expensive options when all you need is a straightforward solution to sign and send documents?
                </p>

                <div class="p-6 pt-12 space-y-4">
                        <p className="text-3xl sm:text-4xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b  from-neutral-300 to-neutral-700 pt-8 text-center mx-auto">
                            The PenX Pro Max Ultra vs. The Bocument Pen 
                        </p>
                        <p className='text-center mx-auto font-semibold pt-10'>Assume you are in a market to buy a pen..</p>
                        <p className="text-1xl px-4 md:text-1xl lg:text-2xl text-neutral-700 dark:text-white max-w-4xl leading-relaxed lg:leading-snug text-center mx-auto font-semibold">
                            PenX Pro Max Ultra writes in seven colors, doubles as a flashlight, and has a mini umbrella. Oh, and James Bond used it once. Cool, right? But itcosts as much as a small island in the Pacific.
                        </p>

                        <p className="text-1xl px-4 md:text-1xl lg:text-2xl text-neutral-700 dark:text-white max-w-4xl leading-relaxed lg:leading-snug text-center mx-auto font-semibold pt-10">
                            With bocument.com, We are just like the regular ballpoint pen that writes and signs:simple, reliable, cheap and nothing fancy.    
                        </p>
                </div>
            </div>
        </section>


        </>
    );
};


const HowAreWeDifferent = (props) => {

    const featureCards = [    {
        title: "Self-Sign a Document",
        description: "Instantly upload any document and swiftly sign it with our intuitive drag-and-drop interface. Signing documents has never been easier or quicker. Download it once done with super speed and no backend saves."
    },
    {
        title: "Seamless Document Sending",
        description: "Need a signature from someone else? Effortlessly upload your document, specify where they need to sign, and send it off. They can sign it with just a few clicks, streamlining the process for both parties."
    },
    {
        title: "Efficient Reusable Templates",
        description: "Create your document once and save it as a template. Next time, just select your template, make any necessary tweaks, and you’re ready to go. It’s the perfect time-saver for frequently used documents."
    },
]
    return (<>
        <section class="features h-full w-full" id="features">
        <div className="h-full w-full items-center justify-center overflow-hidden p-12">

        <div class="max-w-5xl mx-auto w-full pt-12">
            <p className="text-4xl sm:text-7xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-500 to-neutral-800 py-10 text-center mx-auto">
                How are we different?
            </p>

            <p class="text-lg text-gray-700 text-center mb-4 font-semibold">
                While giants like DocuSign, PandaDoc, etc cater to large corporations with a <span class="text-red-500 font-bold">lots of features</span> with <span class="text-red-500 font-bold">higher costs</span>.
            </p>
            <p class="text-lg text-gray-700 text-center font-semibold">
                Our focus is on serving  <span class="text-green-700 font-bold"> individuals, startups,small to medium-sized businesses (SMBs)</span> who need only the <span class="text-green-700 font-bold">core features</span> that seek simplicity, efficiency, and affordability with <span class="text-green-700 font-bold">lesser costs</span>.
            </p>
        </div>
            <div className="w-full flex items-center justify-center overflow-hidden">

                <div className="sm:h-full m:h-[40rem] w-full flex flex-col sm:flex-row sm:flex-wrap items-center justify-center ">
                {
                    featureCards&&featureCards.map(feature=>{
                        return <div className="m-8">
                            <PinContainer
                            title={feature.title}
                            // href="https://bocument.com/"
                        >
                            <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[16rem] h-[18rem] md:w-[20rem] md:h-[20rem]">
                            <h3 className="max-w-xs !pb-2 !m-0 font-bold  text-base text-slate-100">
                                {feature.title}
                            </h3>
                            <div className="text-base !m-0 !p-0 font-normal">
                                <span className="text-slate-500 ">
                                    {feature.description}
                                </span>
                            </div>
                                <div className="flex flex-1 w-full rounded-lg sm:mt-3 md:mt-4 bg-gradient-to-br from-violet-500 via-purple-500 to-blue-500" />
                            </div>
                        </PinContainer>
                    </div>
                    })
                }
                
                </div>

            </div>
            </div>
        </section>
        </>
    );
};


const ContactUs = (props) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const lambdaUrl = process.env.REACT_APP_LAMBDA_CONTACTSALES_API;

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page
    
        try {
            const response = await fetch(lambdaUrl, {
                method: 'POST',
                headers: { 
                },
                body: JSON.stringify({body:JSON.stringify({ email, message })})
        });
    
            if (!response.ok) {
                // If the server response was not OK, throw an error
                throw new Error('Failed to submit. Please try again later.');
            }
    
            const data = await response.json(); // Assuming the server responds with JSON
            setEmail('');
            setMessage('');
            console.log('Response from Lambda:', data);
            alert("We will reach out to you soon.");
        } catch (error) {
            console.error('Error calling Lambda:', error.message);
        }
    };
    

    return (
        <>
            <section className="contactsales" id="contactsales">
                <div className="h-screen h-full w-full rounded-md bg-neutral-950 relative flex flex-col items-center justify-center antialiased">
                    <div className="max-w-2xl mx-auto p-4">
                        <h1 className="text-lg md:text-6xl bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600 text-center font-sans font-bold">
                            Unlock Early Access!
                        </h1>
                        <p className="text-neutral-500 max-w-lg mx-auto my-2 text-sm text-center">
                        Dive into the future of document management and eSignature solutions tailored just for you. 
                        Interested in early access, integrating into your app, 
                        or Whether you're looking to host with us or on your own infrastructure, we're here to guide you. 
                        Submit your details now. 
                        We're excited to connect and explore how Bocument can revolutionize your workflow. 
                        Together, let's unlock new possibilities and grow your success.
                        </p>
                        <form onSubmit={handleSubmit} className="flex flex-col items-center relative z-10">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                className="rounded-lg border border-neutral-800 focus:ring-2 focus:ring-teal-500 w-full relative z-10 mt-4 bg-neutral-950 placeholder:text-neutral-700 text-xl text-white"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <textarea
                                placeholder="Your message"
                                className="rounded-lg border border-neutral-800 focus:ring-2 focus:ring-teal-500 w-full relative z-10 mt-4 bg-neutral-950 placeholder:text-neutral-700 text-xl text-white"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                            <button
                                type="submit"
                                className="mt-4 bg-white hover:shadow-md text-black font-bold py-2 px-6 relative z-10 rounded-lg transition duration-150"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                    <div className='desktop-only'>
                        <BackgroundBeams />
                    </div>
                    
                </div>
            </section>
        </>
    );
};


const LandingPage = () => {

    useEffect(() => {
        if (window.location.hash === '#contactsales') {
            scrollToSection('contactsales');
        }
    }, []);

    return (
        <div className="landing-page-container">

            <LandingBanner 
                title = {"bocument.com"}
                description= {"The Future of Document Signing"}
            />
            <LandingWhyUsNew />
            <GrowWithUs />

            <HowAreWeDifferent />

            <LandingFunnyStory />
            <ContactUs />
            {/* Pricing */}
        </div>
    );
};

export default LandingPage;











    // The Future of Document Signing
    // Go paperless, sign documents electronically, and streamline your workflow.
    // Ease your document management and win your customers.
    // No more hectic and boring paper files in your office. show off and Flatter your employees, customers and clients with paperless documents
    // Get Started - Know More