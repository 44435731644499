import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import Modal from '@mui/material/Modal';
import './SignatureComponent.scss'


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 310,
    background: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    padding: 20
  };

function SignatureModal(props){
    const {isOpen, handleDialogClose, width, height, updateSignatureValue, value} = props
    const signPad = useRef();

    const trim = () => {
        let trimmedDataURL = signPad.current.getTrimmedCanvas().toDataURL('image/png')
        updateSignatureValue(trimmedDataURL)
        handleDialogClose()
    }

    const populateCanvasFromData = () => {
        signPad.current.fromDataURL('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIkAAAAGCAYAAAD3wr3EAAAAAXNSR0IArs4c6QAAAUhJREFUSEvtlrFKxEAQhr89ULQQgnDW1jaCpaWNz2Dpm6yljc9g5XP4CNZaGAsrRXIi2N3K7GYumz2NEuOxQgaWwO5k5t/JP//E0GWWPRzbwCETNpdcHQWGqiNECVTeZ84My01nvvEwywoYj8pyAawD03rdAsfA7h+hFvLoAkOJY4bhHigWxBISBtOnEjL4fL0vMbvIiydtsNgvji91KbFLNYjzankUj8bTun2GsclpqbAUyDNjM5xxguMqY4yrhPYCXjlzNSXddd3AQkZptrSZhsCvuU4N52zxzusQUX8Z4xl4XChJWyniTlVlSVUl3f+uO+U89XkC1qKiywdI1XQ4JWnu+BOsjWoZ7nAe5ypIchnGTRg5O0yYMvcj5w3DPo4D4KgGs9GDBA8101Vi9Z+kPW5E1kfLtgINSfpA/AfztM+1xnfaFfgA/fFch/kY3pAAAAAASUVORK5CYII=')
    }

    useEffect(()=>{
        if(value){
            signPad.current.fromDataURL('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIkAAAAGCAYAAAD3wr3EAAAAAXNSR0IArs4c6QAAAUhJREFUSEvtlrFKxEAQhr89ULQQgnDW1jaCpaWNz2Dpm6yljc9g5XP4CNZaGAsrRXIi2N3K7GYumz2NEuOxQgaWwO5k5t/JP//E0GWWPRzbwCETNpdcHQWGqiNECVTeZ84My01nvvEwywoYj8pyAawD03rdAsfA7h+hFvLoAkOJY4bhHigWxBISBtOnEjL4fL0vMbvIiydtsNgvji91KbFLNYjzankUj8bTun2GsclpqbAUyDNjM5xxguMqY4yrhPYCXjlzNSXddd3AQkZptrSZhsCvuU4N52zxzusQUX8Z4xl4XChJWyniTlVlSVUl3f+uO+U89XkC1qKiywdI1XQ4JWnu+BOsjWoZ7nAe5ypIchnGTRg5O0yYMvcj5w3DPo4D4KgGs9GDBA8101Vi9Z+kPW5E1kfLtgINSfpA/AfztM+1xnfaFfgA/fFch/kY3pAAAAAASUVORK5CYII=')
        }
    })


    return <>
        <Modal
            open={isOpen}
            onClose={handleDialogClose}
        >
            <div className="signature-modal" style={modalStyle}>
                <p>Draw your Signature</p>
                <div style={{border:'1px solid black'}}>
                    <SignatureCanvas penColor='blue' 
                        ref={(ref) => { signPad.current = ref }}
                        canvasProps={{width: 600, height: 200, className: 'sigCanvas'}} /> 
                </div>
                <div className='modal-button-container'>
                    <button className="modal-button" onClick={()=>signPad.current.clear()}>Clear Pad</button>
                    <button className="modal-button" onClick={()=>trim()}>Capture</button>
                </div>
            </div>
                    
        </Modal>
    </>
}

export default SignatureModal