import React, { useEffect, useState } from "react";
import DocMaker from "../../DocMaker/DocMaker";
import FieldSideBarButton from "../../DocMaker/components/FieldSideBarButton";
import FieldsViewSection from "../../DocMaker/components/FieldsViewSection";
import { useDispatch, useSelector } from "react-redux";
import { PREPARATION_FLOW, PAGE_FLOW } from "./../../../utils/constants";
import usePreparationFlow from "../../../hooks/domain/usePreparationFlow";
import { useNavigate } from "react-router-dom";
import { saveTemplate } from "./../../../actions/api/template/templateAction";
import { setDocumentCreationRequestData } from "../../../actions/docflow/documentFlowAction";
import { createTemplate, getTemplateByID, deleteTemplate, clearTemplateData } from "../../../actions/api/template/templateAction";
import { clearUseTemplateData } from "../../../actions/templates/templates";


function SideBarViewData(props){
  const navigate = useNavigate();  
  const dispatch = useDispatch();
  const selectedFileUploadData = useSelector(
    (state) => state.docflow.selectedFileUploadData
  );

  const handleFinishSigning = ()=> {
    navigate("/sign")
  }

  const handleSaveTemplate = ()=> {
    let template = {
      title: props.preparingDocumentData.title,
      description: "A random description for a random template",
      folder: "/",
      bookmarked: false,
      documentFieldsData: props.preparingDocumentData.documentFieldsData,
      fileUploadData: selectedFileUploadData,
      tags: ["health", "insurance"],
      createdBy: Date.now(),
      
    }
    saveTemplate(template).then(data=>{
      try {
        navigate("/template/" + data._id)
      } catch (error) {
        navigate("/templates")
      }
    });
  }

  const handleSendDocument = () => {
    console.log("handleSendDocument");
    dispatch(setDocumentCreationRequestData({...props.preparingDocumentData}))
    navigate("/addsigners")
  }

  return <>
    <div className="space-y-4">
      <h6 className="text-lg font-bold mb-4">{props.preparingDocumentData.title}</h6>
      
        {props.IS_SIGN_YOURSELF_FLOW && (
            <FieldsViewSection label="Sign Yourself">
              <FieldSideBarButton handleClick={() => props.addTextComponentField()} label="Add Text" />
              <FieldSideBarButton handleClick={() => props.addDateComponentField()} label="Add Date" />
              <FieldSideBarButton handleClick={() => props.addSignatureComponentField()} label="Add Signature" />
            </FieldsViewSection>
        )}
        

       
        {(props.IS_SEND_TO_OTHERS_FLOW || props.IS_CREATE_TEMPLATE_FLOW) && (<>
            <FieldsViewSection label="Sender Fields">
                <FieldSideBarButton handleClick={() => props.addTextComponentField()} label="Add Text" />
                <FieldSideBarButton handleClick={() => props.addDateComponentField()} label="Add Date" />
                <FieldSideBarButton handleClick={() => props.addSignatureComponentField()} label="Add Signature" />
                <FieldSideBarButton handleClick={() => handleFinishSigning()} label="Finish Signing" />
            </FieldsViewSection>
            <FieldsViewSection label="Signer Fields">
                <FieldSideBarButton handleClick={() => props.addTextPlaceholder()} label="Add Text Field" />
                <FieldSideBarButton handleClick={() => props.addDatePlaceholder()} label="Add Date Field" />
                <FieldSideBarButton handleClick={() => props.addSignaturePlaceholder()} label="Add Signature Field" />
            </FieldsViewSection>
          </>
        )}

        <FieldsViewSection label="Views">
            {props.IS_SIGN_YOURSELF_FLOW && <FieldSideBarButton handleClick={() => navigate("/signed")} label="Finish Signing" />}
            {props.IS_SEND_TO_OTHERS_FLOW && (
              <>
                <FieldSideBarButton handleClick={() => navigate("/sign")} label="Signer View" />
                <FieldSideBarButton handleClick={() => navigate("/signed")} label="Finish Signing" />
                <FieldSideBarButton handleClick={() => navigate("/template/id")} label="Template View" />
                <FieldSideBarButton handleClick={() => props.handleDownloadPDF()} label="Download PDF" />
                <FieldSideBarButton handleClick={() => handleSendDocument()} label="Send Document" />
              </>
            )}
            {props.IS_CREATE_TEMPLATE_FLOW && <FieldSideBarButton handleClick={() => handleSaveTemplate()} label="Save Template" />}
            {props.IS_USE_TEMPLATE && (
              <>
                <FieldSideBarButton handleClick={() => navigate("/signed")} label="Signed View" />
                <FieldSideBarButton handleClick={() => navigate("/template/id")} label="Delete Template" />
              </>
            )}
        </FieldsViewSection>
    </div>
</>
}


function PrepareDocument() {
  const dispatch = useDispatch();
  const selectedFileUploadData = useSelector(
    (state) => state.docflow.selectedFileUploadData
  );

  const documentCreationRequestData = useSelector(
    (state) => state.docflow.documentCreationRequestData
  );
  const useFromTemplateData = useSelector(
    (state) => state.templates.useTemplateData
  );
  const [preparationFlowData] = usePreparationFlow();
  const [preparingDocumentData, setPreparingDocumentData] = useState({title: documentCreationRequestData.title, ...useFromTemplateData});

  useEffect(()=>{
    //unload
    return ()=>{
      dispatch(clearTemplateData())
      dispatch(clearUseTemplateData())
    }
  },[])

  return (
    <div className="prepare-document-layout-page">
      {(useFromTemplateData||selectedFileUploadData)&&<>
      <DocMaker 
        flow={useFromTemplateData?.documentPDFURL?PAGE_FLOW.USE_TEMPLATE:PAGE_FLOW.PREPARE_DOCUMENT}
        pdfFileDocumentData={useFromTemplateData?.documentPDFURL?useFromTemplateData.documentPDFURL:selectedFileUploadData} 
        preparationFlow={preparationFlowData}
        initialDocumentFieldsData={useFromTemplateData?.documentFieldsData || {}}// -
        CustomSideBarFieldsComponent={SideBarViewData}
        onDocumentFieldDataUpdate={(documentFieldsData)=>{
          setPreparingDocumentData({...preparingDocumentData, documentFieldsData})
        }}
        preparingDocumentData={{...preparingDocumentData}}
        />
      </>}
      <br />
      <br />
    </div>
  );
}

export default PrepareDocument;
