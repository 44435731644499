import React, { useEffect, useState } from "react";
import DocMaker from "../../DocMaker/DocMaker";
import { useDispatch, useSelector } from "react-redux";
import {PREPARATION_FLOW} from '../../../utils/constants'
import samplePDF from "./../../../components/PdfLoader/contract-examples/rental-agreement-room.pdf";
import { deleteDocument, getDocumentByID } from './../../../actions/api/document/documentAction'
import { useNavigate, useParams } from "react-router-dom";
import FieldSideBarButton from "../../DocMaker/components/FieldSideBarButton";
import useAuth from "../../../hooks/domain/useAuth";
import FieldsViewSection from "../../DocMaker/components/FieldsViewSection";

function SideBarViewData(props){

  const navigate = useNavigate();

  const handleDeleteDocument = async ()=> {
    let documentID = props.documentInfoData._id;
    await deleteDocument(documentID)
    navigate("/dashboard")
  }

  return <>
      <FieldsViewSection label={"Views"}>
        <FieldSideBarButton handleClick={()=>handleDeleteDocument()} label="Delete Document" />
        <FieldSideBarButton handleClick={()=>props.handleDownloadPDF()} label="Download PDF" />
      </FieldsViewSection>
      
  </>
}

function OpenedDocumentLayout() {
  const dispatch = useDispatch()
  const { id: documentID } = useParams();
  const [documentData, setDocumentData] = useState()
  const [authInfo, validateAuthToFallback] = useAuth();


  useEffect(()=>{
    validateAuthToFallback();
    // Not using redux to help users open diff docs in multiple tabs
    (async ()=>{
      console.log('documentID', documentID)
      try {
        let response = await getDocumentByID(documentID);
        setDocumentData(response)
      } catch (error) {
        console.log("Error fetching document with id: " + documentID);
      }
    })();
  },[])

  return (
    <div className="opened-document-layout-page">
     {documentData && <DocMaker 
        flow="signed" 
        pdfFileDocumentData={documentData.documentPDFURL||samplePDF}
        preparationFlow={PREPARATION_FLOW.ADMIN_OPENED_DOCUMENT}
        initialDocumentFieldsData={documentData?.signedOn?{}:documentData?.documentFieldsData}
        CustomSideBarFieldsComponent={SideBarViewData}
        documentInfoData={documentData}
        />}
      <br />
      <br />
    </div>
  );
}

export default OpenedDocumentLayout;
