import React, { useEffect, useState } from "react";
import DocMaker from "../../DocMaker/DocMaker";
import { useDispatch, useSelector } from "react-redux";
import {PREPARATION_FLOW, PAGE_FLOW} from './../../../utils/constants'
import { useNavigate, useParams } from "react-router-dom";
import FieldSideBarButton from "../../DocMaker/components/FieldSideBarButton";
import FieldsViewSection from "../../DocMaker/components/FieldsViewSection";
import { getDocumentByID } from './../../../actions/api/document/documentAction'


function SideBarViewData(props){
  return <>
      <FieldsViewSection label={"Views"}>
        <FieldSideBarButton handleClick={()=>props.handleDownloadPDF()} label="Download PDF" />
      </FieldsViewSection>      
  </>
}


function SignedDocumentLayout() {
  const selectedFileUploadData = useSelector(
    (state) => state.docflow.selectedFileUploadData
  );

  const { id: documentID } = useParams();
  const [documentData, setDocumentData] = useState()

  useEffect(()=>{
    // Not using redux to help users open diff docs in multiple tabs
    if(!selectedFileUploadData){
      (async ()=>{
        try {
          let response = await getDocumentByID(documentID);
          setDocumentData(response)
        } catch (error) {
          console.log("Error fetching document with id: " + documentID);
        }
      })();
    }
  },[])

  return (
    <div className="signed-document-layout-page">
      <span style={{ fontSize: "12px" }}>Signed PAGE</span>
      {(selectedFileUploadData||documentData)&&<DocMaker 
        flow={PAGE_FLOW.SIGNED} 
        pdfFileDocumentData={selectedFileUploadData?selectedFileUploadData:documentData.documentPDFURL}
        preparationFlow={PREPARATION_FLOW.SIGNED_DOCUMENT}
        CustomSideBarFieldsComponent={SideBarViewData}
        initialDocumentFieldsData={documentData?.signedOn?{}:documentData?.documentFieldsData}
      />}
      <br />
      <br />
    </div>
  );
}

export default SignedDocumentLayout;
