import React from 'react'
import {PREPARATION_FLOW} from '../../utils/constants'



function FieldSideBar(props) {

    const { flow, preparationFlow, CustomSideBarFieldsComponent } = props;

    let IS_SIGN_YOURSELF_FLOW = (preparationFlow === PREPARATION_FLOW.SIGN_YOURSELF) ? true : false;
    let IS_SEND_TO_OTHERS_FLOW = (preparationFlow === PREPARATION_FLOW.SEND_TO_OTHERS) ? true : false;
    let IS_CREATE_TEMPLATE_FLOW = (preparationFlow === PREPARATION_FLOW.CREATE_TEMPLATE) ? true : false;
    let IS_SIGNED_DOCUMENT_FLOW = (preparationFlow === PREPARATION_FLOW.SIGNED_DOCUMENT) ? true : false;
    let IS_USE_TEMPLATE = (preparationFlow === PREPARATION_FLOW.USE_TEMPLATE) ? true : false;
    let IS_ADMIN_OPENED_DOCUMENT = (preparationFlow === PREPARATION_FLOW.ADMIN_OPENED_DOCUMENT) ? true : false;
    
        
    return (<>
        <div className='field-side-bar-layout'>
         <div className="max-w-lg mx-auto p-6 h-full bg-gray-800 text-white rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold text-center mb-6">Adding Document Fields</h3>
            <div className='fields-container'>
                

                {CustomSideBarFieldsComponent&&<>
                    <CustomSideBarFieldsComponent 
                        {...props} 
                        IS_SIGN_YOURSELF_FLOW={IS_SIGN_YOURSELF_FLOW}
                        IS_SEND_TO_OTHERS_FLOW={IS_SEND_TO_OTHERS_FLOW}
                        IS_CREATE_TEMPLATE_FLOW={IS_CREATE_TEMPLATE_FLOW}
                        IS_SIGNED_DOCUMENT_FLOW={IS_SIGNED_DOCUMENT_FLOW}
                        IS_USE_TEMPLATE={IS_USE_TEMPLATE}
                        IS_ADMIN_OPENED_DOCUMENT={IS_ADMIN_OPENED_DOCUMENT}
                    />
                </>}

            </div>
            <br />
            <br />
            </div>
        </div>
        </>
    )
}

FieldSideBar.defaultProps = {
    flow: "prepare", // prepare || sign || template || signed
}

export default (FieldSideBar)

