import React, { useState } from "react";
import "./index.css";
import ResizeDragComp from "./../../ResizeDragComp";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedComponentData } from "../../../actions/docmaker/docmakerAction";

function DateComponent(props) {
  const { componentId, x, y, width, height, isAnnotation, flow, value } = props;
  const dispatch = useDispatch();
  const selectedComponentData = useSelector(
    (state) => state.docmaker.selectedComponentsData
  );

  const updateComponentData = (componentId, data) => {
    console.log("componentId", componentId);
    console.log("data", data);
    dispatch(setSelectedComponentData(data));
  };

  const handleDragStop = function (e, d) {
    e.preventDefault();
    console.log("d axises", d);
    let { x, y } = d;
    let comp = { ...selectedComponentData[componentId], x, y };
    updateComponentData(componentId, comp);
  };

  const handleResizeStop = function (e, direction, ref, delta, position) {
    e.preventDefault();
    console.log("position", position);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    let comp = { ...selectedComponentData[componentId], value };
    updateComponentData(componentId, comp);
  };

  const isAnnotationInSignerFlow = (flow == "sign" && isAnnotation) || false;
  const isSignedFlow = flow == "signed";
  const isTakingInput = !isAnnotationInSignerFlow && !isSignedFlow;

  return (
    <>
      <ResizeDragComp
        key={componentId}
        x={x}
        y={y}
        width={width}
        height={height}
        styles={
          !isTakingInput
            ? { background: "transparent", border: "transparent" }
            : {}
        }
        enableResizing={!isAnnotation}
        handleDragStop={handleDragStop}
        handleResizeStop={handleResizeStop}
      >
        {!isTakingInput ? (
          <>
            <p>{value}</p>
          </>
        ) : (
          <>
            <input
              type="date"
              defaultValue={value}
              style={{
                background: "#ffff0085",
                width: "inherit",
                height: "inherit",
                fontSize: "16px",
              }}
              onChange={handleInputChange}
            />
          </>
        )}
      </ResizeDragComp>
    </>
  );
}

DateComponent.defaultProps = {
  x: 0,
  y: 0,
  width: 200,
  height: 30,
  isAnnotation: false,
  flow: "prepare",
  value: "",
};

export default DateComponent;
