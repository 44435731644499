import React from "react";
import axios from 'axios';
import PdfLoader from "../../components/PdfLoader";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import {
  setSelectedComponentData,
  setAllComponentsData,
  deleteComponentDataById,
  clearAllSelectedComponentsData
} from "../../actions/docmaker/docmakerAction";
import { useDispatch, useSelector } from "react-redux";
import DocComponentFactory from "../../components/DocumentComponents/DocComponentFactory";
import FieldSideBar from "./FieldSideBar";
import Placeholder from "../../components/DocumentComponents/PlaceholderComponent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";

function DocMaker(props) {
  const {
    flow,
    preparationFlow,
    pdfFileDocumentData,
    initialDocumentFieldsData,
    CustomSideBarFieldsComponent,
    onDocumentFieldDataUpdate
  } = props;

  const [docComponents, setDocComponents] = useState([]);
  const componentCounter = useRef(0);
  const placeholderRef = useRef(null);
  const contentRef = useRef(null);
  const pdfLoaderRef = useRef();

  const dispatch = useDispatch();
  const selectedComponentData = useSelector(
    (state) => state.docmaker.selectedComponentsData
  );

  let handleAddComponent =
    (
      componentType,
      blockText = "",
      isAnnotation = false,
      xPosition = 0,
      yPosition = 0
    ) =>
    (e) => {
      e&&e.preventDefault();
      componentCounter.current = componentCounter.current + 1;
      let compId = "comp" + componentCounter.current;
      let WrapperComponent = DocComponentFactory(componentType);
      setDocComponents((current) => [
        ...current,
        <WrapperComponent
          key={compId}
          id={compId}
          x={xPosition}
          y={yPosition}
          componentId={compId}
          blockText={blockText}
          isAnnotation={isAnnotation}
          handleDeleteComponent={handleDeleteComponent}
        />,
      ]);
      let compData = {
        id: "comp" + componentCounter.current,
        x: xPosition,
        y: yPosition,
        width: 200,
        height: 30,
        type: blockText + "Component",
        addCompType: componentType,
        value: "",
        isAnnotation: isAnnotation,
      };
      dispatch(setSelectedComponentData(compData));
    };

  let simulateHoverPlaceholderComponent =
    (componentType, blockText = "", isAnnotation = false) =>
    (e) => {
      e&&e.preventDefault();

      const handleMouseMove = (event) => {
        const { clientX, clientY, x, y } = event;

        // enabling the placeholder with size and position while placement
        placeholderRef.current.props.style.display = "block";
        placeholderRef.current.updateSize({ width: 200, height: 30 });

        // Has positions of the overlay element {x, y, width, height}
        let draggableOverlayLayerElementPositions = document
          .getElementById("draggable-overlay-layer")
          .getBoundingClientRect();

        if (placeholderRef.current) {
          // calculating cordinates for placement of components
          // substracting the addition space pixels of the draggable layer
          const calculatedX = x - draggableOverlayLayerElementPositions.x - 10;
          const calculatedY = y - draggableOverlayLayerElementPositions.y - 10;
          placeholderRef.current.updatePosition({
            x: calculatedX,
            y: calculatedY,
          });

          placeholderRef.current.props.style.background = "yellow";
        }
      };

      // attach mouse move event to simulate the hover placeholder component
      let draggableOverlayLayerElement = document.getElementById(
        "draggable-overlay-layer"
      );
      draggableOverlayLayerElement.addEventListener(
        "mousemove",
        handleMouseMove
      );

      // on click remove the mouse move and click events
      let placeholderElement = document.getElementById("placeholder-component");
      placeholderElement.addEventListener("click", function clickSim(event) {
        draggableOverlayLayerElement.removeEventListener(
          "mousemove",
          handleMouseMove
        );
        placeholderElement.removeEventListener("click", clickSim);

        const { clientX, clientY, x, y } = event;
        let draggableOverlayLayerElementPositions = document
          .getElementById("draggable-overlay-layer")
          .getBoundingClientRect();
        const calculatedX = x - draggableOverlayLayerElementPositions.x - 10;
        const calculatedY = y - draggableOverlayLayerElementPositions.y - 10;

        // hiding the placeholder with zero size and zero position after placement
        placeholderRef.current.updatePosition({ x: 0, y: 0 });
        placeholderRef.current.updateSize({ width: 0, height: 0 });

        handleAddComponent(
          componentType,
          blockText,
          isAnnotation,
          calculatedX,
          calculatedY
        )(e);
      });
    };

  let handleDeleteComponent = (componentId) => (e) => {
    e.preventDefault();
    console.log("Deleting component with id : ", componentId);
    setDocComponents((current) =>
      current.filter((comp) => {
        return comp.key != componentId;
      })
    );
    dispatch(deleteComponentDataById(componentId));
    e.stopPropagation();
  };

  let loadAddedComponentsForSign = (initialDataLoadObj = null) => {
    let initialDataLoad = [];

    if (initialDataLoadObj) {
      initialDataLoad = Object.keys(initialDataLoadObj).map((key) => {
        let data = initialDataLoadObj[key];
        return data;
      });
    }
    console.log("initialDataLoad",initialDataLoad)

    let compDataArr = Object.keys(selectedComponentData).map((key) => {
      let data = selectedComponentData[key];
      return data;
    });
    console.log("compDataArr",compDataArr)

    compDataArr = compDataArr.concat(initialDataLoad);

    compDataArr.forEach((comp) => {
      let componentType = comp.type;
      let componentBlockText = comp.addCompType;
      if (flow == "template" && !comp.isAnnotation) {
        componentType = "BlockWrapperComponent";
        componentBlockText = comp.type.split("Component").at(0);
      }
      let WrapperComponent = DocComponentFactory(componentType);
      setDocComponents((current) => [
        ...current,
        <WrapperComponent
          key={comp.id}
          id={comp.id}
          componentId={comp.id}
          x={comp.x}
          y={comp.y}
          width={comp.width}
          height={comp.height}
          isAnnotation={comp.isAnnotation || false}
          flow={flow}
          value={comp.value}
          blockText={componentBlockText}
          handleDeleteComponent={handleDeleteComponent}
        />,
      ]);
      dispatch(setSelectedComponentData(comp));
    });
  };

  const clearAllFieldData = () => {
    console.log("clear all field data")
    setDocComponents([]);
    dispatch(clearAllSelectedComponentsData())
  }

  const addTextComponentField = (isAnnotation = true, xPosition = 0, yPosition = 0) => {
    simulateHoverPlaceholderComponent("TextComponent", "Text", isAnnotation)(null);
  }

  const addDateComponentField = (isAnnotation = true, xPosition = 0, yPosition = 0) => {
    simulateHoverPlaceholderComponent("DateComponent", "Date", isAnnotation)(null);
  }

  const addSignatureComponentField = (isAnnotation = true, xPosition = 0, yPosition = 0) => {
    simulateHoverPlaceholderComponent("SignatureComponent", "Signature", isAnnotation)(null);
  }

  const addTextPlaceholder = (isAnnotation = true, xPosition = 0, yPosition = 0) => {
    simulateHoverPlaceholderComponent("BlockWrapperComponent", "Text")(null);
  }

  const addDatePlaceholder = (isAnnotation = true, xPosition = 0, yPosition = 0) => {
    simulateHoverPlaceholderComponent("BlockWrapperComponent", "Date")(null);
  }

  const addSignaturePlaceholder = (isAnnotation = true, xPosition = 0, yPosition = 0) => {
    simulateHoverPlaceholderComponent("BlockWrapperComponent", "Signature")(null);
  }

  function dataURLToImageBytes(dataURL) {

    const base64String = atob(dataURL.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(base64String.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < base64String.length; i++) {
      uint8Array[i] = base64String.charCodeAt(i);
    }
  
    return uint8Array;
  }

  const downloadPDFNEW = async () => {
    const textSize = 20;
    // Getting width and height of rendered pdf in component
    const renderedPages = pdfLoaderRef.current.pages;
    const renderedFirstPage = renderedPages.at(0);
    const { height: renderedPageHeight, width: renderedPageWidth } =
      renderedFirstPage.getBoundingClientRect();

    let pdfData = pdfFileDocumentData;
    if (pdfFileDocumentData.startsWith('http')) {
      // Fetch the PDF data from the URL
      const response = await fetch(pdfFileDocumentData, { responseType: 'arraybuffer' });
      pdfData = await response.arrayBuffer();
    } 
    
    // Getting width and height of actual pdf
    const pdfDoc = await PDFDocument.load(pdfData);
    let pdfComponents = Object.keys(selectedComponentData).map((key) => {
      let data = selectedComponentData[key];
      return data;
    });
    const firstPage = pdfDoc.getPages()[0]; // Get the first page of the PDF
    const { width: pageWidth, height: pageHeight } = firstPage.getSize();

    // Calculate the scaling factors for width and height
    // Adjusting scale factor to adjust positions from rendered dimensions to actual dimensions
    const widthScaleFactor = pageWidth / renderedPageWidth;
    const heightScaleFactor = pageHeight / renderedPageHeight;
    const adjustedTextSize =
      textSize * Math.min(widthScaleFactor, heightScaleFactor);

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    pdfComponents.forEach(async (comp) => {
      const adjustedX = comp.x * widthScaleFactor;
      const adjustedY = comp.y * heightScaleFactor;
      const pageIndex = Math.floor(adjustedY / pageHeight); // Determine the page index based on x position
      const page = pdfDoc.getPages()[pageIndex]; // Get the corresponding page

      // Remove previous pages height(px) and calculate y position for current page
      const currentPageYPosition = adjustedY % pageHeight;
      console.log("comp.type", comp.type);
      if (comp.type.includes("Signature")) {
        // Load the image
        // const imageBytes = await fetch("https://example.com/image.jpg").then((res) => res.arrayBuffer());
        const imageBytes = dataURLToImageBytes(comp.value);
        const image = await pdfDoc.embedPng(imageBytes);

        // Get the image dimensions
        const imageWidth = comp.width;
        const imageHeight = comp.height;

        // Add the image to the PDF
        page.drawImage(image, {
          x: adjustedX, // X position of the top-left corner of the image
          y: pageHeight - currentPageYPosition - parseFloat(imageHeight), // Y position of the top-left corner of the image
          width: parseFloat(imageWidth), // Width of the image
          height: parseFloat(imageHeight), // Height of the image
        });
      } else {
        const blueColor = rgb(0 / 255, 0 / 255, 255 / 255);
        const blackColor = rgb(0 , 0 , 0);
        page.drawText(comp.value, {
          x: adjustedX,
          // y cordinate : In pdf-lib library, y cordinate is calculated from below position
          y: pageHeight - currentPageYPosition - adjustedTextSize,
          size: adjustedTextSize,
          font: font,
          color: blueColor,
        });
      }
    });

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = "download.pdf";
    downloadLink.click();
  };

  const downloadBufferAsFile = (buffer, filename, mimeType)=>  {
    const blob = new Blob([buffer], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  }



  const generateBufferFromPDF = async () => {
    const textSize = 20;
    // Getting width and height of rendered pdf in component
    const renderedPages = pdfLoaderRef.current.pages;
    const renderedFirstPage = renderedPages.at(0);
    const { height: renderedPageHeight, width: renderedPageWidth } =
      renderedFirstPage.getBoundingClientRect();

    let pdfData = pdfFileDocumentData;
    if (pdfFileDocumentData.startsWith('http')) {
      // Fetch the PDF data from the URL
      const response = await fetch(pdfFileDocumentData, { responseType: 'arraybuffer' });
      pdfData = await response.arrayBuffer();
    } 
    
    // Getting width and height of actual pdf
    const pdfDoc = await PDFDocument.load(pdfData);
    let pdfComponents = Object.keys(selectedComponentData).map((key) => {
      let data = selectedComponentData[key];
      return data;
    });
    const firstPage = pdfDoc.getPages()[0]; // Get the first page of the PDF
    const { width: pageWidth, height: pageHeight } = firstPage.getSize();

    // Calculate the scaling factors for width and height
    // Adjusting scale factor to adjust positions from rendered dimensions to actual dimensions
    const widthScaleFactor = pageWidth / renderedPageWidth;
    const heightScaleFactor = pageHeight / renderedPageHeight;
    const adjustedTextSize =
      textSize * Math.min(widthScaleFactor, heightScaleFactor);

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    pdfComponents.forEach(async (comp) => {
      const adjustedX = comp.x * widthScaleFactor;
      const adjustedY = comp.y * heightScaleFactor;
      const pageIndex = Math.floor(adjustedY / pageHeight); // Determine the page index based on x position
      const page = pdfDoc.getPages()[pageIndex]; // Get the corresponding page

      // Remove previous pages height(px) and calculate y position for current page
      const currentPageYPosition = adjustedY % pageHeight;
      console.log("comp.type", comp.type);
      if (comp.type.includes("Signature")) {
        // Load the image
        // const imageBytes = await fetch("https://example.com/image.jpg").then((res) => res.arrayBuffer());
        const imageBytes = dataURLToImageBytes(comp.value);
        const image = await pdfDoc.embedPng(imageBytes);

        // Get the image dimensions
        const imageWidth = comp.width;
        const imageHeight = comp.height;

        // Add the image to the PDF
        page.drawImage(image, {
          x: adjustedX, // X position of the top-left corner of the image
          y: pageHeight - currentPageYPosition - parseFloat(imageHeight), // Y position of the top-left corner of the image
          width: parseFloat(imageWidth), // Width of the image
          height: parseFloat(imageHeight), // Height of the image
        });
      } else {
        const blueColor = rgb(0 / 255, 0 / 255, 255 / 255);
        const blackColor = rgb(0 , 0 , 0);
        page.drawText(comp.value, {
          x: adjustedX,
          // y cordinate : In pdf-lib library, y cordinate is calculated from below position
          y: pageHeight - currentPageYPosition - adjustedTextSize,
          size: adjustedTextSize,
          font: font,
          color: blueColor,
        });
      }
    });

    const pdfBytes = await pdfDoc.save();
    // Convert the bytes to a base64 string
    // const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(pdfBytes)));
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    // Buffer
    return pdfBlob;
  };

  useEffect(() => {
    console.log("docComponents", docComponents);
  }, [docComponents]);

  useEffect(() => {
    console.log("selectedComponentData", selectedComponentData);
    onDocumentFieldDataUpdate(selectedComponentData)
  }, [selectedComponentData]);

  useEffect(() => {
    if (flow == "sign" || flow == "signed" || flow == "template" || flow == "use-template") {
      console.log(flow + " flow in progress");
      loadAddedComponentsForSign(initialDocumentFieldsData);
    }
  }, [flow]);

  useEffect(()=>{
    // Unload
    return ()=>{
      clearAllFieldData()
    }
  },[])

  return (
    <>
      <div className="doc-maker-layout-page">
        <div
          id="wrapper-doc-layer-container"
          className="wrapper-doc-layer-container"
        >
          <div
            ref={contentRef}
            id="wrapper-doc-sub-container"
            className="wrapper-doc-sub-container"
          >
            <div
              id="pdf-document-background-layer"
              className="pdf-document-background-layer"
            >
              <PdfLoader
                id="pdf-loader-component"
                ref={pdfLoaderRef}
                pdfFileData={pdfFileDocumentData}
              />
            </div>

            <div
              id="draggable-overlay-layer"
              className="draggable-overlay-layer"
            >
              {props.flow === "prepare" && <Placeholder ref={placeholderRef} />}

              {docComponents &&
                docComponents.length > 0 &&
                [...docComponents].map((DocComponent, index) => {
                  return DocComponent;
                })}
            </div>
          </div>
        </div>

        <FieldSideBar
          {...props}
          CustomSideBarFieldsComponent={CustomSideBarFieldsComponent}
          flow={flow}
          preparationFlow={preparationFlow}
          handleAddComponent={simulateHoverPlaceholderComponent}
          addTextComponentField={addTextComponentField}
          addDateComponentField={addDateComponentField}
          addSignatureComponentField={addSignatureComponentField}
          addTextPlaceholder={addTextPlaceholder}
          addDatePlaceholder={addDatePlaceholder}
          addSignaturePlaceholder={addSignaturePlaceholder}
          handleDownloadPDF={downloadPDFNEW}
          generateBufferFromPDF={generateBufferFromPDF}
        />

        <br />
        <br />
      </div>
    </>
  );
}

DocMaker.defaultProps = {
  flow: "prepare", // prepare || sign || signed || template
  preparationFlow: "sign-yourself", // sign-yourself || send-to-others || create-template || use-template
  CustomSideBarComponent: null,
  onDocumentFieldDataUpdate: ()=>{}
};


export default DocMaker;
