import React from "react";
import DocMaker from "../../DocMaker/DocMaker";
import FieldSideBarButton from "../../DocMaker/components/FieldSideBarButton";
import FieldsViewSection from "../../DocMaker/components/FieldsViewSection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PREPARATION_FLOW, PAGE_FLOW } from "./../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { getDocumentByID } from "../../../actions/api/document/documentAction";
import { createDocument, signDocument } from "../../../actions/api/document/documentAction";

function SideBarViewData(props) {
  
  const {documentResponseData: documentData, workingDocumentData} = props;

  const navigate = useNavigate();

  const handleFinishSigning = async ()=> {
    let documentRequest = {...documentData, ...workingDocumentData}
    const pdfBlob = await props.generateBufferFromPDF();
    documentRequest.fileUploadData = pdfBlob;
    await signDocument(documentRequest);
    navigate("/signed/" + documentRequest._id);
  }

  return (
    <>
      {props.IS_SEND_TO_OTHERS_FLOW && (
        <>
          <FieldsViewSection label={"Views"}>
              <FieldSideBarButton handleClick={() => handleFinishSigning()} label="Finish Signing" />
          </FieldsViewSection>      
        </>
      )}
    </>
  );
}

function SignPageLayout() {
  const [documentData, setDocumentData] = useState();
  const { id: documentID } = useParams();

  useEffect(() => {
    // Not using redux to help users open diff docs in multiple tabs
    (async () => {
      console.log("documentID", documentID);
      try {
        let response = await getDocumentByID(documentID);
        setDocumentData(response);
      } catch (error) {
        console.log("Error fetching document with id: " + documentID);
      }
    })();
  }, []);

  const selectedFileUploadData = useSelector(
    (state) => state.docflow.selectedFileUploadData
  );

  const [workingDocumentData, setWorkingDocumentData] = useState({...documentData});


  return (
    <div className="dashboard-layout-page">
      <span style={{ fontSize: "12px" }}>SIGNER PAGE</span>
      {(selectedFileUploadData || documentData)&&<DocMaker
        flow={PAGE_FLOW.SIGN}
        // pdfFileDocumentData={selectedFileUploadData}
        pdfFileDocumentData={selectedFileUploadData?selectedFileUploadData:documentData.documentPDFURL}
        preparationFlow={PREPARATION_FLOW.SEND_TO_OTHERS}
        CustomSideBarFieldsComponent={SideBarViewData}
        initialDocumentFieldsData={documentData?.documentFieldsData || []} // -
        documentResponseData={documentData}
        onDocumentFieldDataUpdate={(documentFieldsData)=>{
          setWorkingDocumentData({...workingDocumentData, documentFieldsData})
        }}
        workingDocumentData={{...workingDocumentData}}
      />}
      <br />
      <br />
    </div>
  );
}

export default SignPageLayout;
