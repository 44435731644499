import React, { useState, useEffect } from 'react'

/**
 * @function useInputField
 * @description React Hook to use state values for input fields
 * @param {any} defaultValue
 * @param {boolean} specialCharsAllow 
 * @returns {any} 
 * @author Sarat Chandra Ejjapureddi
 */
export default function useInputField(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)

    const handleInputValue = (e) => {
        e&&e.preventDefault();
        if (e) {
            let val = e.target.value
            setValue(val)
        }
    }

    return [value, setValue, handleInputValue]
}