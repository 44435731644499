import React, { useEffect, useState } from "react";
import ResizeDragComp from "./../../ResizeDragComp";
import { Rnd } from "react-rnd";

const Placeholder = React.forwardRef(({ id, type, handleDragStop},ref) => {
  return (
    <Rnd
      id={"placeholder-component"}
      bounds="parent"
      default={{
        width: 0,//150,
        height: 0,//35,
        x: 0,
        y: 0,
      }}
      style={{background: 'yellow'}}
      ref={ref}
      // onDragStop={(e, d) => handleDragStop(id, d.x, d.y)}
      // onClick={()=>{console.log("click got called")}}
    >
      <div style={{width:"inherit", overflow: "hidden"}}>
        {type} Placeholder {id}
      </div>
    </Rnd>
  );
});

export default Placeholder;
