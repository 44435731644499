import React from "react";
import "../styles.scss";

const FieldSideBarButton = ({ handleClick, label }) => (
  <button
    onClick={handleClick}
    className="text-black bg-white hover:bg-white-200 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 mb-2 transition duration-150 ease-in-out"
  >
    {label}
  </button>
);

FieldSideBarButton.defaultProps = {};

export default FieldSideBarButton;
