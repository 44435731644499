import React, { useState } from 'react'
import './index.css'
import { Rnd } from "react-rnd";

const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#f0f0f0"
  };

/**
 * https://github.com/bokuweb/react-rnd
 * 
 * 
 * 
 * @returns <ResizeDragComp />
 */
function ResizeDragComp(props){

    let {x, y, width, height, minWidth, minHeight, enableResizing, disableDragging, handleDragStop, handleResizeStop, styles} = props

    const handleDragStopSnippet = function(e, d){
        e.preventDefault();
        console.log("d axises", d);
    }

    const handleResizeStopSnippet = function(e, direction, ref, delta, position){
        e.preventDefault();
        console.log("position", position);
    }

    return <>
        <Rnd
            style={{...style,...styles}}
            bounds="parent" //bounds and draggable upto its parent div
            default={{
            x: x,
            y: y,
            width: width,
            height: height,
            }} 
            minWidth={minWidth}
            minHeight={minHeight}
            enableResizing={enableResizing}
            disableDragging={disableDragging}
            onDragStop={handleDragStop}
            onResizeStop={handleResizeStop}
            >
                {props.children}
        </Rnd>
    </>
}


ResizeDragComp.defaultProps = {
    x: 0,
    y: 0,
    width: 200,
    height: 30,
    minWidth: 150,
    minHeight: 25,
    enableResizing: true,
    disableDragging: false,
    styles: {}
}


export default ResizeDragComp