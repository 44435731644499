import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * 
 * Created for library 
 * https://ui.aceternity.com/components
 * 
 * @export
 * @param {any} inputs 
 * @returns 
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}


